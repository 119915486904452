<div
  data-layout="column"
  data-layout-align="center center"
  data-layout-gap="16px"
  class="noItemsContainer"
  [ngClass]="{ resetHeight: resetHeight, resetPadding: resetPadding }"
  [class]="padding"
>
  <img src="/assets/img/no_items_logo.svg" alt="No Items" title="No items" draggable="false" />
  <div data-layout="column" data-layout-gap="8px" data-layout-align="start stretch">
    <span class="imageLabel" [attr.data-cy]="dataCy">{{ label | language }}</span>
    <span *ngIf="additionalLabel" class="imageLabel">{{ additionalLabel | language }}</span>
    <ng-template [ngTemplateOutlet]="customTemplateBottom"></ng-template>
  </div>
</div>
