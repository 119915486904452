import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { PhysicianModel } from '@shared/models';

@Component({
  selector: 'app-form-name',
  templateUrl: './form-name.component.html',
  styleUrls: ['./form-name.component.scss'],
  standalone: false
})
export class FormNameComponent extends DestroySubscriptions implements OnInit {
  @Input() set physician(value: PhysicianModel) {
    this.setValue(value);
  }
  @Input() createEntityFlow: boolean = false;
  @Input() tags: { tagText: string }[] = [];

  @Output() formNameValueChangeEmitter = new EventEmitter<UntypedFormGroup>();
  @Output() addTagsEmitter = new EventEmitter<string>();
  @Output() removeTagsEmitter = new EventEmitter<{ tagText: string }>();

  private formBuilder = inject(UntypedFormBuilder);

  formName: UntypedFormGroup;

  ngOnInit(): void {
    this.createForm();
    this.formChanges();
  }

  addTags(tagText: string): void {
    this.addTagsEmitter.emit(tagText);
    this.formName.markAsDirty();
  }

  removeTags(item: { tagText: string }): void {
    this.removeTagsEmitter.emit(item);
    this.formName.markAsDirty();
  }

  private createForm(): void {
    this.formName = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      middleName: ['', [Validators.maxLength(255)]]
    });
  }

  private formChanges(): void {
    setTimeout(() => {
      this.formNameValueChangeEmitter.emit(this.formName);
    });
    this.formName.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formNameValueChangeEmitter.emit(this.formName);
    });
  }

  private setValue(data: PhysicianModel): void {
    if (!data.firstName) {
      return;
    }
    this.formName.setValue({
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName
    });
  }
}
