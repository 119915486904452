<div data-layout="column" data-layout-gap="4px">
  <mat-label *ngIf="label" class="radioKitLabel">{{ label }}</mat-label>
  <mat-radio-group
    data-cy="radioKit"
    class="radioKit"
    color="primary"
    [attr.data-layout]="layout"
    [ngClass]="customClass"
    [class.disabled]="disabled"
    [ngModelOptions]="{ standalone: true }"
    [attr.data-layout-gap]="gap ? gap : layout === 'row' ? '20px' : '10px'"
    (ngModelChange)="radioEmitter.emit($event)"
    [(ngModel)]="value"
  >
    <mat-radio-button
      *ngFor="let radio of items"
      data-cy="radioKitItem"
      [disabled]="radio.disabled"
      [disableRipple]="true"
      [attr.data-cy-value]="radio[parseValueByProperty || 'value']"
      [value]="radio[parseValueByProperty || 'value']"
    >
      {{ translateValue ? (radio[parseKeyByProperty || 'key'] | language) : radio[parseKeyByProperty || 'key'] }}
      @if (radio?.boldKey) {
        <b>{{ radio.boldKey }}</b>
      }
      <app-tooltip-kit *ngIf="radio.tooltip" [title]="radio.tooltip" [size]="16" [position]="'above'" class="radioKitTooltip" />
    </mat-radio-button>
  </mat-radio-group>
</div>
