<span
  class="connectSxTab"
  data-flex="100"
  [ngClass]="{
    connectSxTabActive: selected,
    disabled: disabled,
    dashboardTab: customClass === 'dashboardTab'
  }"
  (click)="clickEmitter.emit()"
  >{{ title }}</span
>
