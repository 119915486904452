<div [ngClass]="{ 'form-group-emphasized': !expandedSection, 'last-child': lastChild }" data-layout="column" data-layout-gap="16px">
  <div data-layout="row" data-layout-align="space-between center" data-layout-gap="8px">
    <div data-layout="row" class="form-group-emphasized-wrap" data-layout-gap="8px" data-layout-align="start center">
      <app-title [title]="commentsTitle || ('shared.labels.comments' | language)" [type]="titleType"></app-title>
      <app-tooltip-kit *ngIf="tooltipText" [title]="tooltipText" [position]="'above'"></app-tooltip-kit>
    </div>
    <ng-container *appHasPermission="{ scope: 'common', permissionName: 'addComment' }">
      <app-button-kit
        *ngIf="(showNewCommentField$ | async) === false"
        data-hide-lt-lg
        [dataCy]="'leaveCommentButton'"
        [type]="'secondary'"
        [title]="'shared.labels.leaveCommentButton' | language"
        (clickEmitter)="openAddCommentField()"
      ></app-button-kit>
      <app-button-kit
        *ngIf="(showNewCommentField$ | async) === false"
        data-hide
        data-show-lt-lg
        [iconSize]="24"
        [icon]="'plus'"
        (clickEmitter)="openAddCommentField()"
      ></app-button-kit>
    </ng-container>
  </div>

  <app-user-autocomplete
    *ngIf="displayCommentRecipients"
    [required]="false"
    [showOpenInNewIcon]="true"
    [showClearIcon]="false"
    [multiple]="true"
    [usersForMentions]="true"
    [label]="'shared.labels.commentRecipients' | language"
    [removable]="allowEdit && permissionService.isGranted('common', 'addComment')"
    [disabled]="!allowEdit || !permissionService.isGranted('common', 'addComment')"
    [allowViewDetails]="currentUser.role === 'ADMIN'"
    [selectedChips]="recipients"
    (optionSelectedEmitter)="recipientAdded.emit($event)"
    (removeChipEmitter)="recipientRemoved.emit($event)"
  ></app-user-autocomplete>

  <div [hidden]="(showNewCommentField$ | async) === false" data-layout="column" data-layout-gap="16px">
    <app-wysiwyg
      id="commentInput"
      data-flex="100"
      [maxLength]="1000"
      [value]="newComment"
      (valueChanged)="newComment = $event"
    ></app-wysiwyg>

    <div data-layout="row" data-layout-align="end center" data-layout-gap="16px">
      <app-button-kit
        data-flex="100px"
        data-flex-lt-lg="100"
        data-cy="addCommentSaveButton"
        [color]="'success'"
        [textColor]="'white'"
        [size]="(isMobile$ | async) === true ? 'medium' : 'small'"
        [disabled]="!newComment?.length"
        [type]="'primary'"
        [title]="'shared.labels.save' | language"
        (clickEmitter)="saveComment()"
      ></app-button-kit>

      <app-button-kit
        data-flex="100px"
        data-flex-lt-lg="100"
        data-cy="addCommentCancelButton"
        [color]="'warn'"
        [size]="(isMobile$ | async) === true ? 'medium' : 'small'"
        [type]="'secondary'"
        [title]="'shared.labels.cancel' | language"
        (clickEmitter)="newComment = ''; showNewCommentField$.next(false)"
      ></app-button-kit>
    </div>
  </div>

  <app-comments-list
    [stopEdit]="showNewCommentField$ | async"
    [notes]="comments"
    [allowEdit]="allowEdit"
    [showCommentMarker]="showCommentMarker"
    (editStartedEmitter)="showNewCommentField$.next(false)"
    (editCommentEmitter)="editComment($event)"
    (markCommentEmitter)="markCommentEmitter.emit($event)"
  ></app-comments-list>
</div>
