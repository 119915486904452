<div
  class="inputKitContainer"
  [ngClass]="{
    valueExist: focused || value,
    invalidInput: (error || inputRef.invalid) && inputRef.touched,
    disabledInput: disabled,
    required: required && !disabled,
    small: size === 'small',
    borderTransparent: borderTransparent,
    focused: focused && !borderTransparent
  }"
>
  <div data-layout="row" data-layout-align="space-between center" data-layout-gap="8px">
    <mat-form-field class="inputFormField" [ngClass]="{ valueExist: input.value || focused, invalidInput: error, disabledInput: disabled }">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <input
        #input
        #inputRef="ngModel"
        matInput
        appDebounceEvent
        autocomplete="off"
        maxlength="255"
        name="inputKit"
        [readonly]="readonly"
        [type]="type"
        [required]="required"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [attr.data-cy]="dataCy"
        [ngModel]="value"
        [mask]="mask"
        [min]="min"
        [maxlength]="maxLength"
        [validation]="validation"
        [allowNegativeNumbers]="allowNegativeNumbers"
        [thousandSeparator]="thousandSeparator"
        [dropSpecialCharacters]="dropSpecialCharacters"
        [ngModelOptions]="{ standalone: true }"
        (focus)="focused = true; focusEmitter.emit()"
        (blur)="focused = false; input.value = input.value.trim(); blurEmitter.emit(input.value)"
        (keyup.enter)="enterEmitter.emit()"
        (ngModelChange)="updateValue($event)"
      />
    </mat-form-field>

    <div *ngIf="icons.length" data-layout="row" data-layout-gap="8px" class="iconsContainer" data-layout-align="center center">
      <ng-container *ngFor="let icon of icons">
        <app-icon
          *ngIf="input.value && icon === 'close'"
          [icon]="'close'"
          [type]="'outline'"
          [size]="16"
          (click)="$event.stopPropagation(); iconClickEmitter.emit(icon); input.value = ''"
        ></app-icon>

        <div *ngIf="icon === 'download' || icon === 'scan'" data-layout="row" data-layout-gap="8px">
          <ng-content></ng-content>
        </div>

        <app-icon
          *ngIf="icon && icon !== 'close' && icon !== 'help' && icon !== 'scan' && icon !== 'download'"
          [icon]="icon"
          [type]="'outline'"
          [size]="20"
          (click)="$event.stopPropagation(); iconClickEmitter.emit(icon)"
        ></app-icon>
        <app-tooltip-kit *ngIf="icon === 'help'" [title]="tooltipText" (click)="$event.stopPropagation()"></app-tooltip-kit>
      </ng-container>
    </div>
  </div>
  <span *ngIf="hint" class="inputHint">{{ hint }}</span>
  <span *ngIf="error" class="inputHint errorHint">{{ error }}</span>
</div>
