import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CatalogService } from '@services/catalog.service';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { CatalogModel, ManufacturerModel, ProductLineModel } from '@shared/models';
import { IFilter } from '@shared/interfaces';
import { CatalogsPageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { PermissionService } from '@services/internal/permission.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { LanguageService } from '@services/internal/language.service';
import { DisplayReferenceNumber } from '@shared/utils/form-elements/display-with-autocomplete';
import { ICatalogsAuditInfo, ICatalogsAuditInfoParams } from '@shared/interfaces/audits';
import { TItemType } from '@shared/type/index.type';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-catalog-autocomplete',
  templateUrl: './catalog-autocomplete.component.html',
  styleUrls: ['./catalog-autocomplete.component.scss'],
  standalone: false
})
export class CatalogAutocompleteComponent implements OnChanges {
  @Input() catalog: CatalogModel = new CatalogModel();
  @Input() label: string = LanguageService.instant('shared.labels.catalog');
  @Input() showEmptyCatalogsOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() filterView: boolean = false;
  @Input() showAddNewButton: boolean = false;
  @Input() showManufacturerName: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() borderTransparent: boolean = false;
  @Input() multiple: boolean = false;
  @Input() removable: boolean = false;
  @Input() tooltip: TranslationsKeys;
  @Input() selectedItems: CatalogModel[] = [];
  @Input() manufacturers: ManufacturerModel[] = [];
  @Input() products: ProductLineModel[] = [];
  @Input() invalid: boolean = false;
  @Input() clearAfterSelection: boolean = false;
  @Input() custodyId: string = '';
  @Input() type: TItemType;
  @Input() types: TItemType[];
  @Input() isSearchAuditCatalogs: boolean = false;
  @Input() selectedAuditCatalogs: ICatalogsAuditInfo[] = [];
  @Input() cacheableAutocomplete: boolean = false;
  @Input() maxChipsCount: number = 50;
  @Input() skipSelectSingleOption: boolean = false;
  @Input() isConnectedToPack: boolean;
  @Input() icons: TIcons[] = [];
  @Input() isHideFromSearchAfterSelection: boolean = false;

  @Output() selectedOptionEmitter = new EventEmitter<CatalogModel>();
  @Output() selectedAuditOptionEmitter = new EventEmitter<ICatalogsAuditInfo>();
  @Output() addCatalogEmitter = new EventEmitter<void>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() valueChangedEmitter = new EventEmitter<string>();

  permissionService = inject(PermissionService);
  private catalogService = inject(CatalogService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: CatalogModel[] = [];
  searchedAuditCatalogsData: ICatalogsAuditInfo[] = [];
  forceBlurEvent: boolean = false;
  searchValue: string = '';
  successfulSearchParams: CatalogsPageableParams;
  readonly displayFn = DisplayReferenceNumber;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.products?.currentValue) {
      this.searchedData = [];
    }
  }

  selectCatalog(catalog: CatalogModel): void {
    if (this.clearAfterSelection) {
      this.catalog = new CatalogModel();
    }

    this.selectedOptionEmitter.emit(catalog);

    if (this.multiple || this.isHideFromSearchAfterSelection) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== catalog.id);
    }
  }

  setSearchValue(value: CatalogModel | string): void {
    this.searchValue = typeof value === 'string' ? value : value?.referenceNumber || '';
  }

  selectAuditCatalog(auditCatalog: ICatalogsAuditInfo): void {
    if (this.clearAfterSelection) {
      this.catalog = new CatalogModel();
    }
    this.selectedAuditOptionEmitter.emit(auditCatalog);
    this.searchedAuditCatalogsData = this.searchedAuditCatalogsData.filter(c => c.catalogId !== auditCatalog.catalogId);
  }

  addCatalog(): void {
    this.addCatalogEmitter.emit();
  }

  searchCatalogs(value: string, skipSelectSingleOption: boolean = false, page: number = 0): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }
    const params: IFilter = {
      size: DefaultAutoCompleteSize,
      search: value,
      state: 'ACTIVE',
      page: page,
      sort: 'referenceNumber,asc'
    };

    if (this.products.length) {
      params.productIds = this.products.filter(p => p !== null).map(p => p.id);
    }

    if (this.manufacturers.length) {
      params.manufacturerIds = this.manufacturers.filter(m => m !== null).map(p => p.id);
    }

    if (this.showEmptyCatalogsOnly) {
      params.backOrder = true;
    }

    if (this.type) {
      params.itemType = this.type;
    }

    if (this.types?.length) {
      params.inventoryTypes = this.types;
    }

    if (typeof this.isConnectedToPack === 'boolean') {
      params.isConnectedToPack = false;
    }

    const queryParams: CatalogsPageableParams = new CatalogsPageableParams(params);

    // If no new search params are added, get cached autocomplete data
    if (
      this.cacheableAutocomplete &&
      this.successfulSearchParams &&
      JSON.stringify(this.successfulSearchParams) === JSON.stringify(queryParams)
    ) {
      this.searchedData = FilterUniqueArray(this.searchedData, this.selectedItems);
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];
    this.searchedAuditCatalogsData = [];

    if (this.isSearchAuditCatalogs) {
      this.searchAuditCatalogs(value, skipSelectSingleOption);
      return;
    }

    this.catalogService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.searchedData = FilterUniqueArray(data.content, this.selectedItems);
        this.successfulSearchParams = queryParams;

        if ((skipSelectSingleOption || this.skipSelectSingleOption) && this.searchedData.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!value && this.searchedData.length === 1 && this.required) {
          this.selectCatalog(this.searchedData[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
        //CC-5432 Load next page if all items selected
        if (this.searchedData?.length === 0 && data?.totalElements > this.selectedItems?.length && page < data.totalPages) {
          setTimeout(() => this.searchCatalogs(value, skipSelectSingleOption, page + 1));
        }
      });
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchCatalogs('', true);
    }
  }

  removeChip(id: string): void {
    this.removeChipEmitter.emit(id);
  }

  private searchAuditCatalogs(value: string, skipSelectSingleOption: boolean = false, page: number = 0): void {
    const queryParams: ICatalogsAuditInfoParams = {
      manufacturerId: this.manufacturers[0]?.id,
      searchText: value,
      size: DefaultAutoCompleteSize,
      page
    };

    queryParams.custodyId = this.custodyId ?? '';

    const productIds: string[] = this.products.filter(p => p !== null).map(p => p.id);

    if (productIds.length) {
      queryParams.productId = productIds[0];
    }

    if (this.type) {
      queryParams.inventoryType = this.type;
    }

    this.catalogService
      .getAuditInfo(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.searchedAuditCatalogsData = data.content.filter(r => !this.selectedAuditCatalogs.some(s => s.catalogId === r.catalogId));

        if ((skipSelectSingleOption || this.skipSelectSingleOption) && this.searchedAuditCatalogsData.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!value && this.searchedAuditCatalogsData.length === 1 && !this.selectedAuditCatalogs.length) {
          this.selectAuditCatalog(this.searchedAuditCatalogsData[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
        //CC-5432 Load next page if all items selected
        if (
          this.searchedAuditCatalogsData?.length === 0 &&
          data?.totalElements > this.selectedAuditCatalogs?.length &&
          page < data.totalPages
        ) {
          setTimeout(() => this.searchAuditCatalogs(value, skipSelectSingleOption, page + 1));
        }
      });
  }
}
