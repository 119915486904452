<div class="full-width" data-layout="row">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'facilityInput'"
    [chipDataCy]="'facilityChip'"
    [chipRemoveData_cy]="'facilityRemoveChipButton'"
    [selectedChips]="selectedItems"
    [label]="'shared.labels.facilityAutocomplete' | language"
    [disabled]="disabled"
    [invalidInput]="invalid"
    [selectable]="true"
    [showAddChipButton]="true"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [forceBlurEvent]="forceBlurEvent"
    [required]="required"
    [removable]="removable"
    [detailPageLink]="'/directory/facilities/edit/'"
    [autocompleteName]="facilityAutocomplete"
    (chipsRemovedEmitter)="removeFacilityChip($event.id)"
    (iconClickEmitter)="doIconAction($event)"
    (debounceTimeEndedEmitter)="searchFacilities($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [label]="label"
      [invalidInput]="invalid"
      [filterView]="filterView"
      [dataCy]="'facilityInput'"
      [initialValue]="facility"
      [disabled]="disabled"
      [debounceEvents]="['keyup', 'focus']"
      [forceBlurEvent]="forceBlurEvent"
      [debounceTime]="1000"
      [icons]="[showClearIcon ? 'close' : null, facility?.id && showOpenInNewIcon ? 'link' : null]"
      [autocompleteName]="facilityAutocomplete"
      [linkDetailPage]="
        permissionService.isGranted('common', 'visitDetailPageAssignedEntity') ? '/directory/facilities/edit/' + facility?.id : null
      "
      [showInvalidStateOnInit]="showInvalidStateOnInit"
      (debounceTimeEndedEmitter)="searchFacilities($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
      (iconClickEmitter)="doIconAction($event)"
    ></app-input-kit>
  </ng-template>

  <mat-autocomplete #facilityAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true; else options" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-template #options>
      <mat-option *ngIf="!searchedData.length; else data" disabled class="connectSxAutocompleteOption">
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>
      <ng-template #data>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
        >
          <mat-option
            data-cy="facilityOption"
            *cdkVirtualFor="let facility of searchedData"
            class="connectSxAutocompleteOption"
            [value]="facility"
            (click)="selectOption(facility)"
          >
            <span>{{ facility.name }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-template>
    <mat-option
      *ngIf="
        permissionService.isGranted('directory', 'createFacility') &&
        (loading$ | async) === false &&
        (initialLoading$ | async) === false &&
        showAddNewButton
      "
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="createFacility()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
