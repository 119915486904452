import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalConfig } from '@constants';
import { LanguageService } from '@services/internal/language.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { take } from 'rxjs';
import { ConfirmComponent } from '../confirm/confirm.component';
import { DefaultModalComponent } from '@shared/components/modals/default-modal/default-modal.component';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-create-entity-modal',
  templateUrl: './create-entity-modal.component.html',
  styleUrls: ['./create-entity-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CreateEntityModalComponent implements AfterViewInit {
  @ViewChild('createEntityModalTitleContainer') titleContainer: ElementRef<HTMLDivElement>;
  @ViewChild('createEntityModalButtonsContainer') buttonsContainer: ElementRef<HTMLDivElement>;

  @Input() title: string = '';
  @Input() showNextStepButton: boolean = false;
  @Input() showPrevStepButton: boolean = false;
  @Input() showCreateButton: boolean = false;
  @Input() size: 'medium' | 'small' = 'medium';
  @Input() disableNextStepButton: boolean = false;
  @Input() disableCreateButton: boolean = false;
  @Input() confirmationRequired: boolean = false;
  @Input() createButtonTitle: TranslationsKeys = 'shared.labels.create';
  @Input() showCloseConfirmation: boolean = false;
  @Input() preventDefaultClose: boolean = false;
  @Input() closeModalOnCreate: boolean = true;
  @Input() set triggerCloseModal(data: boolean) {
    if (data) {
      this.dialogRef.close();
    }
  }

  @Output() prevStepEmitter = new EventEmitter<void>();
  @Output() nextStepEmitter = new EventEmitter<void>();
  @Output() createEmitter = new EventEmitter<void>();
  @Output() closeEmitter = new EventEmitter<void>();

  /** Close modal on click link */
  @HostListener('click', ['$event'])
  clickInside(event: any) {
    if (GlobalStoreService.getPlatform() !== 'web') {
      setTimeout(() => this.ref.detectChanges());
    }
    if (!event || typeof event.composedPath !== 'function') {
      return;
    }
    const isClickToLink = event
      .composedPath()
      .findIndex((e: HTMLElement) => e.tagName === 'A' && e.getAttribute('data-link') !== 'skipClosing');
    if (isClickToLink !== -1) {
      this.dialogRef.close();
    }
  }

  contentHeight: number = null;

  constructor(
    public dialogRef: MatDialogRef<CreateEntityModalComponent>,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    const dialogWindows: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
      'mat-mdc-dialog-container'
    ) as HTMLCollectionOf<HTMLElement>;
    const dialogWindowHeight: number = dialogWindows[dialogWindows.length - 1].offsetHeight;
    const titleContainerHeight: number = this.titleContainer.nativeElement.offsetHeight;
    const buttonsContainerHeight: number = this.buttonsContainer.nativeElement.offsetHeight;
    const dialogWindowPadding: number = 24 * 2;
    const titleContainerMargin: number = 20;
    const saveArea = DefaultModalComponent.getSafeArea('afe-area-inset-top') + DefaultModalComponent.getSafeArea('safe-area-inset-bottom');

    this.contentHeight =
      dialogWindowHeight - titleContainerHeight - buttonsContainerHeight - dialogWindowPadding - titleContainerMargin - saveArea;
    this.ref.markForCheck();
  }

  close(): void {
    if (!this.showCloseConfirmation) {
      if (this.preventDefaultClose) {
        this.closeEmitter.emit();
      } else {
        this.dialogRef.close();
      }
      return;
    }

    const dialogRef = this.dialog.open(
      ConfirmComponent,
      ConfirmModalConfig({
        description: LanguageService.instant('shared.alerts.prompt.closeModal'),
        title: LanguageService.instant('shared.alerts.prompt.closeModalTitle'),
        acceptButtonText: LanguageService.instant('shared.alerts.prompt.buttons.yes'),
        declineButtonText: LanguageService.instant('shared.alerts.prompt.buttons.no'),
        acceptButtonStyle: 'warn'
      })
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(value => {
        if (value) {
          if (this.preventDefaultClose) {
            this.closeEmitter.emit();
          } else {
            this.dialogRef.close();
          }
        }
      });
  }

  create(): void {
    this.createEmitter.emit();
    if (!this.confirmationRequired && this.closeModalOnCreate) {
      this.dialogRef.close(true);
    }
  }

  nextStep(): void {
    this.nextStepEmitter.emit();
  }

  prevStep(): void {
    this.prevStepEmitter.emit();
  }
}
