import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { TEventPaidStatus, TEventStatus, TEventType, TFacilityGeographicalType, TSalesOrderStatus } from '@shared/type/index.type';

export class EventPageableParamsBuilder {
  commissionPaid?: TEventPaidStatus;
  datetime?: string;
  eventName?: string;
  eventStatus?: TEventStatus;
  eventType?: TEventType;
  eventTypes?: TEventType[];
  facilityGeographicalType?: TFacilityGeographicalType;
  facilityName?: string;
  facilityStockEvent?: boolean;
  hasLastInvoiceSentDatetime?: boolean;
  hasLastSalesOrderSentDatetime?: boolean;
  inOrgCustody?: boolean;
  manufacturerId?: string;
  page?: number;
  physicianName?: string;
  procedureName?: string;
  processedStatus?: TSalesOrderStatus;
  representativeId?: string;
  invoiceNumber?: string;
  invoicePaid?: TEventPaidStatus;
  poNumber?: string;
  size?: number;
  sort?: string[];
  datetimeFrom?: string | Date;
  datetimeTo?: string | Date;
  hideCancelled?: boolean;
  eventStatuses?: TEventStatus[];
  number?: string;
  createdDatetimeFrom?: string;
  createdDatetimeTo?: string;
  patientId?: string;

  constructor(params: IFilter) {
    return EventPageableParamsBuilder.build(params);
  }

  private static build(params: IFilter): EventPageableParamsBuilder {
    const queryParams: EventPageableParamsBuilder = DefaultQueryParams(params);

    queryParams.datetime = params.datetime;
    queryParams.invoiceNumber = params.invoiceNumber;
    queryParams.poNumber = params.poNumber;
    queryParams.datetimeFrom = params.datetimeFrom;
    queryParams.datetimeTo = params.datetimeTo;
    queryParams.facilityStockEvent = params.facilityStockEvent;
    queryParams.hasLastInvoiceSentDatetime = params.hasLastInvoiceSentDatetime;
    queryParams.hasLastSalesOrderSentDatetime = params.hasLastSalesOrderSentDatetime;
    queryParams.facilityGeographicalType = params.geographicalType;

    if (params.eventStatus) {
      queryParams.eventStatus = params.eventStatus;
    }

    if (params.eventName && params.eventName !== 'eventName') {
      queryParams.eventName = params.eventName;
    }

    if (params.facilityName && params.facilityName !== 'facilityName') {
      queryParams.facilityName = params.facilityName;
    }

    if (params.physicianName && params.physicianName !== 'physicianName') {
      queryParams.physicianName = params.physicianName;
    }

    if (params.procedureName && params.procedureName !== 'procedureName') {
      queryParams.procedureName = params.procedureName;
    }

    if (params.patientId && params.patientId !== 'patientId') {
      queryParams.patientId = params.patientId;
    }

    if (params.custodyId) {
      if (params.custodyId === 'ORGANIZATION') {
        queryParams.inOrgCustody = true;
      } else {
        queryParams.representativeId = params.custodyId;
      }
    }

    if (params.manufacturer && typeof params.manufacturer !== 'boolean') {
      queryParams.manufacturerId = params.manufacturer;
    }

    queryParams.hideCancelled = params.hideCancelled;

    if (params.eventNumber) {
      queryParams.number = params.eventNumber;
    }

    if (params.invoicePaid) {
      queryParams.invoicePaid = params.invoicePaid;
    }

    if (params.commissionPaid) {
      queryParams.commissionPaid = params.commissionPaid;
    }

    if (params.processedStatus) {
      queryParams.processedStatus = params.processedStatus;
    }

    if (params.createdDatetimeFrom) {
      queryParams.createdDatetimeFrom = params.createdDatetimeFrom;
    }

    if (params.createdDatetimeTo) {
      queryParams.createdDatetimeTo = params.createdDatetimeTo;
    }

    if (params.eventType) {
      queryParams.eventType = params.eventType;
    }

    if (params.eventTypes?.length) {
      queryParams.eventTypes = params.eventTypes;
    }

    if (params.eventStatuses?.length) {
      queryParams.eventStatuses = params.eventStatuses;
    }
    return ClearRequest(queryParams);
  }
}
