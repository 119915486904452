<div class="full-height" data-layout="column" data-layout-gap="16px" data-layout-align="start stretch">
  <div class="staticProgressBarContainer" *ngIf="loading">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>
  <div *ngIf="notifications.length && !loading" class="notificationsList" data-layout="column" data-layout-gap="8px">
    <div *ngFor="let notify of notifications; trackBy: trackBy" class="notificationItem" data-layout="column" data-layout-gap="8px">
      <div data-layout="row" data-layout-align="space-between start" data-layout-gap="8px">
        <span class="notificationTitle">{{ notify.subject }}</span>
        <div>
          <a
            class="notificationViewButton"
            *ngIf="
              notify.entityType === 'INVENTORY' &&
              (notify.subject === 'Device movement logged' ||
                notify.subject === 'Inventory due back extension accepted' ||
                notify.subject === 'Inventory due back extension declined')
            "
            (click)="viewEmitter.emit()"
            routerLink="/inventory/inventories/edit/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'STATISTICS' && notify.subject === 'Overdue inventory'"
            (click)="viewEmitter.emit(); defineDueBackStatus(notify.message)"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'STATISTICS' && notify.subject === 'Reserved event for container'"
            (click)="viewEmitter.emit(); defineReservationDate(notify.message, notify.createdDatetime)"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'STATISTICS' && notify.subject === 'Expired inspection'"
            (click)="viewEmitter.emit(); defineInspectionDue(notify.message)"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'REQUEST' && notify.subject !== 'User mentioned'"
            (click)="viewEmitter.emit()"
            routerLink="/movement/requests/edit/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'INVENTORY_IMPORT'"
            (click)="viewEmitter.emit()"
            routerLink="/inventory/import/detail/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'EVENT' && notify.subject !== 'User mentioned' && isAdmin"
            (click)="viewEmitter.emit()"
            routerLink="/events/edit/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'TRANSFER' && notify.subject !== 'User mentioned'"
            (click)="viewEmitter.emit()"
            routerLink="/movement/transfers/edit/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.subject === 'Inventory due back extension requested'"
            (click)="navigateToDueBackExtension(); viewEmitter.emit()"
            routerLink="/movement/transfers/edit/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="
              notify.entityType === 'STATISTICS' &&
              !notify.subject.toLowerCase().includes('overdue inventory') &&
              !notify.subject.toLowerCase().includes('reserved event for container') &&
              !notify.subject.toLowerCase().includes('expired inventory') &&
              !notify.subject.toLowerCase().includes('expired inspection') &&
              !notify.subject.toLocaleLowerCase().includes('report run')
            "
            (click)="viewEmitter.emit()"
            routerLink="/inventory/inventories/edit/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'INVITATION'"
            (click)="viewEmitter.emit()"
            routerLink="/users/{{ notify.entityId }}"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.subject === 'User mentioned'"
            [routerLink]="getMentionedPage(notify)"
            (click)="viewEmitter.emit()"
            >{{ 'notifications.viewLabel' | language }}</a
          >

          <a
            class="notificationViewButton"
            *ngIf="notify.entityType === 'AUDIT_INVENTORY'"
            [routerLink]="'/inventory/audits/edit/' + notify.entityId"
            (click)="viewEmitter.emit()"
            >{{ 'notifications.viewLabel' | language }}</a
          >
        </div>
      </div>
      <p [innerHTML]="bypassSecurityTrustHtml(notify.message)" class="notificationMessage"></p>
      <span class="caption">{{ notify.createdDatetime | datetimeAgo }}</span>
    </div>
  </div>

  <p data-layout-align="center center" *ngIf="!notifications.length" class="notificationMessage noItems full-height">
    {{
      ('notifications.noNewNotifications' | language) + (isActive('/notifications') ? '' : '. ' + ('notifications.clickViewAll' | language))
    }}
  </p>
</div>
