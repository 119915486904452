import { NoteModel } from '@shared/models/shared/note.model';
import { InventoryModel } from '@shared/models/features/inventory/inventories/inventory.model';
import { InventoryRequestModel } from '@shared/models/features/movement/inventory-request/inventory-request-model';
import { TDueBackStatus, TState, TTransferShipmentType } from '@shared/type/index.type';
import { EventModel, LocationModel, ShipToModel, UserModel } from '../../../index';
import { ECarrier, ETransferStatus } from '@shared/enum';
import { ITrack } from '@shared/interfaces/tracking';
import { DateTimeString, UniqueId } from '@shared/models/shared/shared-kernel';
import { ITransferFacilityNotification } from '@shared/interfaces/transfers/transfer-facility-notification';
import { UpdateTransferredInventoryParams } from '@shared/models/request-models';

export class TrackingModel {
  carrier: keyof typeof ECarrier | '';
  trackingNumber: string = '';
  shippingCosts?: string;
}

export class TransferTracking {
  createdDatetime: DateTimeString;
  modifiedDatetime: DateTimeString;
  id: UniqueId;
  carrier: string; //keyof typeof ECarrier
  trackingNumber: string;
  shippingCosts: number;
  labelUrl: string;
  shipmentId: UniqueId;
  createdDateTimeInShippo: DateTimeString;
  estimatedDeliveryDate: string;
  currency: string;
  packingSlipUrl: string;
  trackingUrlProvider: string;
  track: ITrack;

  constructor() {
    this.carrier = 'fedex';
    this.trackingNumber = null;
    this.shippingCosts = null;
  }
}

export class TransferModel {
  assignedDevices?: InventoryModel[];
  comments?: NoteModel[];
  commentsCount: number | null;
  createdBy: UserModel;
  createdDatetime: string;
  disabled?: boolean; // Front-end property
  expanded?: boolean; // Front-end property
  id: string;
  event: EventModel;
  inventories: (null | InventoryModel)[];
  inventoryRequest: InventoryRequestModel;
  modifiedBy: UserModel;
  modifiedDatetime: string;
  name: string;
  nearestDueBackStatus: TDueBackStatus;
  note: string;
  number: number;
  recipient: UserModel;
  sender: UserModel;
  shippingAddress: ShipToModel;
  state: TState;
  status: keyof typeof ETransferStatus;
  tracking: TrackingModel;
  acceptedOn: string;
  acceptedBy: UserModel;
  location: LocationModel;
  locationIsChanging: boolean;
  dueBackDate: string;
  dueBackDateIsChanging: boolean;
  tags: string;
  returnedAuthorization: string;
  inventoryUpdateParameters: UpdateTransferredInventoryParams;
  // 'ACCEPTED' means transfer was marked as shipped
  // 'INCOMPLETE' transfer was reported
  // possible 2 statuses in array at the same time if transfer was shipped & reported. Need to take current status & pass the new one
  shippingStatus: TTransferShipmentType[];
  shippedChangedOn: DateTimeString;
  shippedChangedBy: UserModel;
  transferFacilityNotification: ITransferFacilityNotification | null;
  transferTrackings: TransferTracking[];
}
