<div
  *ngIf="!skeleton"
  class="inputKitContainer"
  [ngClass]="{
    valueExist: focused,
    invalidInput: ((error || inputRef.invalid || invalidInput) && inputRef.touched) || (invalidInput && showInvalidStateOnInit),
    disabledInput: disabled,
    required: required && !disabled,
    small: size === 'small',
    borderTransparent: borderTransparent,
    filterView: filterView,
    background: background,
    deprecated: deprecated,
    focused: focused && !borderTransparent
  }"
>
  <mat-form-field
    class="inputFormField"
    [ngClass]="{
      valueExist: input.value || type === 'time' || focused || autocomplete?.panelOpen || (browserAutofillVisible$ | async) === true,
      disabledInput: disabled,
      additionalPadding: type === 'time',
      searchInput: searchInput
    }"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      #input
      #inputRef="ngModel"
      matInput
      appDebounceEvent
      autocomplete="off"
      [maxlength]="maxlength"
      name="inputKit"
      [ngClass]="{ timeInput: type === 'time' && value }"
      [readonly]="readonly"
      [type]="type"
      [required]="required"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [attr.data-cy]="dataCy"
      [pattern]="pattern"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: true }"
      [matAutocomplete]="autocompleteName"
      [matAutocompleteDisabled]="!autocompleteName"
      [debounceEvents]="debounceEvents"
      [debounceTime]="debounceTime"
      (debounceEventEmitter)="checkValueBeforeEmit(input.value)"
      (keyup.enter)="enterEmitter.emit(input.value)"
      (focus)="focused = true; focusEmitter.emit(); scrollTo(input)"
      (change)="inputChangedEmitter.emit($event)"
      (blur)="focused = false; input.value = input.value.trim(); keepFocus ? input.focus() : null; blurEmitter.emit()"
      (ngModelChange)="updateValue($event); inputTouched.emit(true)"
    />
    <div *ngIf="icons.length" data-layout="row" data-layout-gap="8px" class="iconsContainer" data-layout-align="center center">
      <ng-container *ngFor="let icon of icons">
        <app-icon
          *ngIf="input.value && icon === 'close'"
          [icon]="'close'"
          [type]="'outline'"
          [size]="16"
          (click)="$event.stopPropagation(); input.value = ''; clearAutocomplete()"
        ></app-icon>

        <div *ngIf="icon === 'download' || icon === 'scan' || icon === 'edit_square'" data-layout="row" data-layout-gap="8px">
          <ng-content></ng-content>
        </div>

        <app-icon
          *ngIf="icon === 'filter'"
          [icon]="'filter'"
          [type]="'outline'"
          [size]="20"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="matMenuName"
          (click)="$event.stopPropagation()"
        ></app-icon>

        <a *ngIf="icon === 'link' && linkDetailPage && isWebPlatform" (click)="$event.stopPropagation(); openLinkInBrowser(linkDetailPage)">
          <app-icon [icon]="icon" [color]="'primary'" [type]="'outline'" [size]="20"></app-icon>
        </a>

        <app-icon
          *ngIf="
            icon &&
            icon !== 'close' &&
            icon !== 'help' &&
            icon !== 'scan' &&
            icon !== 'download' &&
            icon !== 'edit_square' &&
            icon !== 'filter' &&
            icon !== 'plus' &&
            icon !== 'link'
          "
          [icon]="icon"
          [disabled]="disableIcon"
          [type]="'outline'"
          [size]="20"
          (click)="$event.stopPropagation(); disableIcon ? '' : iconClickEmitter.emit(icon)"
        ></app-icon>

        <app-tooltip-kit
          *ngIf="icon === 'help'"
          [color]="deprecated ? 'orange' : 'default'"
          [title]="tooltipText"
          [icon]="deprecated ? 'danger' : 'help'"
          [position]="tooltipPosition"
          (click)="$event.stopPropagation()"
        />

        <app-icon
          *ngIf="icon === 'plus'"
          matTooltip="Create new"
          matTooltipClass="connectSxTooltipMessage"
          matTooltipPosition="below"
          [icon]="'plus'"
          [type]="'outline'"
          [size]="20"
          [color]="'primary'"
          (click)="$event.stopPropagation(); iconClickEmitter.emit(icon)"
        ></app-icon>
      </ng-container>
    </div>
  </mat-form-field>
  <span *ngIf="hint || (browserAutofillHint$ | async)" class="inputHint">{{ hint || (browserAutofillHint$ | async) }}</span>
  <span *ngIf="error" class="inputHint errorHint">{{ error }}</span>
</div>

<app-string-skeleton *ngIf="skeleton" [defaultInput]="true"></app-string-skeleton>
