<div data-layout="row">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'manufacturerInput'"
    [chipDataCy]="'manufacturerChip'"
    [selectedChips]="selectedItems"
    [label]="label"
    [showAddChipButton]="true"
    [invalidInput]="invalid"
    [disabled]="disabled"
    [forceBlurEvent]="forceBlurEvent"
    [debounceEvents]="['keyup', 'focus']"
    [selectable]="!disabled"
    [required]="required && !selectedItems.length"
    [requiredView]="required && !!selectedItems.length"
    [debounceTime]="1000"
    [showLinkToDetail]="showLinkToDetail && permissionService.isGranted('common', 'visitDetailPageAssignedEntity')"
    [removable]="removable"
    [detailPageLink]="'/directory/manufacturers/edit/'"
    [autocompleteName]="manufacturerAutocomplete"
    (chipsRemovedEmitter)="removeManufacturer($event.id)"
    (debounceTimeEndedEmitter)="searchManufacturer($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [label]="label"
      [dataCy]="'manufacturerInput'"
      [initialValue]="manufacturer"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [forceBlurEvent]="forceBlurEvent"
      [icons]="[
        !disabled ? 'close' : null,
        showLinkToDetail && manufacturer?.id && permissionService.isGranted('common', 'visitDetailPageAssignedEntity') ? 'link' : null
      ]"
      [filterView]="filterView"
      [disabled]="disabled"
      [linkDetailPage]="'/directory/manufacturers/edit/' + manufacturer?.id"
      [borderTransparent]="borderTransparent"
      [autocompleteName]="manufacturerAutocomplete"
      (debounceTimeEndedEmitter)="searchManufacturer($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
      (iconClickEmitter)="doIconAction($event)"
    ></app-input-kit>
  </ng-template>

  <mat-autocomplete #manufacturerAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>

    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option *ngIf="!(searchedData$ | async).length; else options" disabled class="connectSxAutocompleteOption">
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>
      <ng-template #options>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
        >
          <mat-option
            *cdkVirtualFor="let manufacturer of searchedData$ | async"
            data-cy="manufacturerOption"
            class="connectSxAutocompleteOption"
            [value]="manufacturer"
            (click)="selectOption(manufacturer)"
          >
            <p>{{ manufacturer.name }}</p>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>

    <mat-option
      *ngIf="
        permissionService.isGranted('directory', 'createManufacturer') &&
        (loading$ | async) === false &&
        (initialLoading$ | async) === false &&
        showAddNew
      "
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="addNewEmitter.emit()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
