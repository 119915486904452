<!--Assets group -->
<form
  class="form-group-emphasized"
  data-layout="column"
  [ngClass]="{ 'last-child nullMargin': createEntityFlow }"
  [attr.data-layout-gap]="createEntityFlow ? '24px' : '16px'"
  [formGroup]="assets"
>
  <app-title [title]="'products.productAssetsLabel' | language" [type]="'h5'"></app-title>

  <div class="form-group-emphasized-container" data-layout="column" data-layout-gap="16px">
    <div [attr.data-layout]="createEntityFlow ? 'column' : 'row'" data-layout-xs="column" data-layout-gap="16px">
      <app-textarea-kit
        data-flex="50"
        data-flex-xs="100"
        formControlName="description"
        [label]="'products.descriptionColumnTitle' | language"
        [hint]="'shared.tooltips.maxLength1000'"
        [dataCy]="'productDescriptionInput'"
        [initialValue]="assets.get('description').value"
        [disabled]="assets.get('description').disabled"
      ></app-textarea-kit>
      <app-input-kit
        data-flex="50"
        data-flex-xs="100"
        formControlName="assetUrl"
        [dataCy]="'productAssetUrlInput'"
        [initialValue]="assets.get('assetUrl').value"
        [label]="'products.assetUrlInputLabel' | language"
        [disabled]="assets.get('assetUrl').disabled"
        [invalidInput]="assets.get('assetUrl').invalid"
        [pattern]="'https://.*'"
        [type]="'url'"
      ></app-input-kit>
    </div>
  </div>
</form>
