<div
  *ngIf="!skeleton"
  class="selectKitContainer"
  data-layout="row"
  data-layout-align="space-between center"
  data-layout-gap="4px"
  [ngClass]="{
    valueExist: touched && value,
    invalidInput: (error || selectRef.invalid) && selectRef.touched,
    disabledInput: disabled,
    required: required && !disabled,
    borderTransparent: borderTransparent,
    filterView: filterView,
    focused: select.panelOpen && !borderTransparent
  }"
>
  <mat-form-field
    class="selectFormField"
    [ngClass]="{
      valueExist: showNoneOption || showAnyOption || value || focused,
      invalidInput: error,
      disabledInput: disabled,
      overflowHidden: overflowHidden
    }"
  >
    <mat-label>{{ label }}</mat-label>
    <mat-select
      #select
      #selectRef="ngModel"
      disableOptionCentering="true"
      panelClass="matSelectDisableOptionCentering matSelectScrollingPanel"
      [ngClass]="{ limitMaxWidth: hint }"
      [ngModelOptions]="{ standalone: true }"
      [placeholder]="placeholder"
      [required]="required"
      [disabled]="disabled"
      [attr.data-cy]="dataCy"
      [attr.data-cy-select-value]="value"
      (opened)="focused = true; touched = true"
      (closed)="focused = false"
      (ngModelChange)="updateValue($event)"
      [(ngModel)]="value"
    >
      <mat-option *ngIf="showAnyOption" value="">--{{ 'shared.labels.any' | language }}--</mat-option>
      <mat-option *ngIf="showNoneOption" value="" [disabled]="disableNoneOption">{{ noneOptionLabel }}</mat-option>
      @for (item of items; track item.id || item; let i = $index) {
        @let optionText =
          translateValue
            ? ((parseKeyByItem
                ? item
                : parseKeyByAdditionalProperty
                  ? item[parseKeyByProperty || 'key'][parseKeyByAdditionalProperty]
                  : item[parseKeyByProperty || 'key']
              ) | language)
            : parseKeyByItem
              ? item
              : parseKeyByAdditionalProperty
                ? item[parseKeyByProperty || 'key'][parseKeyByAdditionalProperty]
                : item[parseKeyByProperty || 'key'];

        <mat-option
          [ngClass]="{ colorPaletteOption: displayColorPalette }"
          [attr.data-cy]="optionDataCy"
          [attr.data-cy-value]="
            parseValueByIndex ? i : parseValueByItem && !optionDataCyValueShowString ? item : item[parseValueByProperty || 'value']
          "
          [value]="parseValueByIndex ? i : parseValueByItem ? item : item[parseValueByProperty || 'value']"
          [disabled]="i === compareOptionWith || (useDisabledInOptions && item?.disabled)"
          [hidden]="hiddenOption?.length ? item[hiddenOption[0]] !== hiddenOption[1] : false"
          [matTooltip]="showHoverForLongOptions ? optionText : null"
          matTooltipPosition="right"
          matTooltipClass="connectSxTooltipMessage"
          [matTooltipDisabled]="!showHoverForLongOptions"
        >
          <ng-template
            *ngIf="customOptionTemplate; else defaultOption"
            ngFor
            let-item
            [ngForOf]="[item]"
            [ngForTemplate]="customOptionTemplate"
          ></ng-template>
          <ng-template #defaultOption>
            <span class="optionText">{{ optionText }}</span>
            <div *ngIf="displayColorPalette" class="themeSquare" [ngClass]="item['key']">
              <div class="firstPart" data-flex="33.3%"></div>
              <div class="secondPart" data-flex="33.3%"></div>
              <div class="thirdPart" data-flex="33.3%"></div>
            </div>
          </ng-template>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="iconsContainer" *ngIf="hint" data-layout="row" data-layout-gap="8px" data-layout-align="center center">
    <app-tooltip-kit *ngIf="hint" [title]="hint" (click)="$event.stopPropagation()"></app-tooltip-kit>
  </div>
</div>
<span *ngIf="error" class="selectHint errorHint">{{ error }}</span>

<app-string-skeleton *ngIf="skeleton" [defaultInput]="true"></app-string-skeleton>
