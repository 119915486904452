<div class="staticProgressBarContainer">
  <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
</div>
<div class="pageTabsContainer">
  <div
    data-cy="searchedItemsTab"
    class="connectSxPageTab base"
    [ngClass]="{ connectSxPageTabActive: selectedView === 'SEARCHED' }"
    (click)="selectedView = 'SEARCHED'"
  >
    {{ 'shared.labels.searched' | language | uppercase }}
  </div>
  <div
    data-cy="selectedItemsTab"
    class="connectSxPageTab base"
    [ngClass]="{ connectSxPageTabActive: selectedView === 'SELECTED' }"
    (click)="selectedView = 'SELECTED'"
  >
    {{ ('shared.labels.selected' | language | uppercase) + ' (' + selectedCatalogs.length + ')' }}
  </div>
</div>

<div
  *ngIf="selectedView === 'SEARCHED'"
  class="requestItemsContainer"
  data-layout="column"
  data-layout-gap="8px"
  [attr.data-layout-align]="!searchedCatalogs.length ? 'center center' : 'start stretch'"
>
  <app-catalog-item
    *ngFor="let catalog of searchedCatalogs; trackBy: trackById"
    [view]="'SEARCHED'"
    [catalog]="catalog"
    (itemSelected)="selectCatalogEmitter.emit($event)"
  ></app-catalog-item>

  <app-empty-state *ngIf="!searchedCatalogs.length && !loading" [label]="'shared.labels.noItems'" [resetPadding]="true"></app-empty-state>
</div>

<div
  *ngIf="selectedView === 'SELECTED'"
  class="requestItemsContainer"
  data-layout="column"
  data-layout-gap="8px"
  [attr.data-layout-align]="!selectedCatalogs.length ? 'center center' : 'start stretch'"
>
  <app-catalog-item
    *ngFor="let catalog of selectedCatalogs; trackBy: trackById"
    [view]="'SELECTED'"
    [catalog]="catalog"
    (itemRemoved)="removeCatalogEmitter.emit($event)"
  ></app-catalog-item>

  <app-empty-state *ngIf="!selectedCatalogs.length" [resetPadding]="true"></app-empty-state>
</div>
