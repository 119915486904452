<div
  data-layout="column"
  class="inventoryOptionItem"
  [ngClass]="{ transparent: backgroundTransparent, fullWidthView: fullWidthView }"
  [@expandCollapse]="openAnimation"
  data-cy="searchedInventories"
>
  <div
    class="pointer"
    [ngClass]="{ selectedInventoryInfoContainer: showItemsCount }"
    data-layout="column"
    data-layout-gap="4px"
    (click)="$event.stopPropagation(); pushDevice(invent)"
  >
    <div data-layout="row">
      <div data-flex="85" data-flex-lt-md="80" data-layout="row" data-layout-xs="column" data-layout-gap-xs="4px">
        <div data-flex="50" data-layout="column" data-layout-gap="4px">
          <span class="inventoryValue" *ngIf="invent.name" data-cy="inventoryToTransferOptionName">
            <app-tooltip-on-ellipsis>{{ invent.name }}</app-tooltip-on-ellipsis>
          </span>
          <span class="inventoryLabel" *ngIf="invent.custodyName">
            {{ 'shared.labels.custody' | language }}:
            <span class="inventoryValue" [ngClass]="{ colorRed: custody?.name && custody?.id !== invent.custodyId }">{{
              invent.custodyName
            }}</span>
          </span>

          <span class="inventoryLabel" *ngIf="!invent.custodyName"
            >{{ 'shared.labels.org' | language }}: <span class="inventoryValue">{{ companyName }}</span></span
          >

          <span class="inventoryLabel" *ngIf="invent.udi">
            {{ 'shared.labels.udi' | language }}:
            <span class="inventoryValue">{{ invent.udi }}</span>
          </span>
          <span *ngIf="!invent.udi" class="inventoryLabel"
            >{{ 'shared.labels.udi' | language }}: <span class="inventoryValue">-</span></span
          >

          <span *ngIf="invent.lotNumber" class="inventoryLabel">
            {{ 'shared.labels.lot' | language }}:
            <span class="inventoryValue">{{ invent.lotNumber }}</span>
          </span>
          <span class="inventoryLabel" *ngIf="!invent.lotNumber"
            >{{ 'shared.labels.lot' | language }}: <span class="inventoryValue">-</span></span
          >
        </div>

        <div data-flex="50" data-layout="column" data-layout-gap="4px">
          <span class="inventoryLabel" *ngIf="invent.serialNumber">
            {{ 'shared.labels.serial' | language }}:
            <span class="inventoryValue">{{ invent.serialNumber }}</span>
          </span>
          <span class="inventoryLabel" *ngIf="!invent.serialNumber"
            >{{ 'shared.labels.serial' | language }}: <span class="inventoryValue">-</span></span
          >

          <span class="inventoryLabel" data-cy="searchedInventoryRefNumber">
            {{ 'shared.labels.catalog' | language }}:
            <span class="inventoryValue" data-cy="catalogName">{{ invent.referenceNumber }}</span>
          </span>

          <span class="inventoryValue" *ngIf="invent.inventoryType !== 'CONTAINER'">
            {{
              invent.parentReferenceNumber
                ? ('shared.labels.contId' | language) + ': ' + invent.parentReferenceNumber
                : ('shared.statuses.loose' | language)
            }}
          </span>

          <ng-container>
            <span data-hide data-cy="searchResultsExpirationStatus">{{ invent.expirationStatus }}</span>
            <span data-hide data-cy="searchResultsLocationId">{{ invent.locationId }}</span>
            <span data-hide data-cy="searchResultsBillable">{{ invent.billable || false }}</span>
            <span data-hide data-cy="searchResultsInventoryType">{{ invent.inventoryType }}</span>
            <span *ngIf="invent.inventoryType === 'PACK'"
              ><span class="emphasizedGray">{{ 'shared.labels.pack' | language }}</span></span
            >
            <span class="inventoryValue colorRed" *ngIf="invent.expirationStatus === 'EXPIRED'">{{
              'shared.statuses.expired' | language
            }}</span>
            <span *ngIf="invent?.expiredDays !== null && invent?.expiredDays > 0" class="inventoryValue colorOrange">{{
              ('shared.statuses.expiresIn_1' | language) + ' ' + invent.expiredDays + ' ' + ('shared.statuses.days' | language)
            }}</span>
            <span class="inventoryValue colorBlue" *ngIf="invent.billable === false">{{ 'shared.statuses.unbillable' | language }}</span>
          </ng-container>
        </div>
      </div>
      <div data-flex="15" data-flex-lt-md="20" data-layout-align="end start">
        <span class="selectItemButton"> {{ ('shared.labels.select' | language) + ' >' }} </span>
      </div>
    </div>

    <div *ngIf="displayTags && tags.length" class="tagsContainer" data-layout="row wrap">
      <span *ngFor="let tag of tags" class="emphasizedGray">{{ tag }}</span>
    </div>
  </div>
  <div *ngIf="showItemsCount" data-layout="row" data-layout-align="end center" class="inventoryCountContainer">
    <app-select-items-count
      *ngIf="!readonly"
      [selectedCount]="quantity"
      [totalCount]="invent.count"
      [ind]="index"
      [backgroundTransparent]="true"
      (changeCount)="quantity = $event"
    ></app-select-items-count>

    <span *ngIf="readonly" class="inventoryLabel"
      >{{ ('shared.labels.available' | language) + ':' }} <b>{{ invent.count }}</b></span
    >
  </div>
</div>
