<app-default-modal
  [title]="'facilities.addFacilityContactsLabel' | language"
  [buttonTitle]="'shared.labels.save' | language"
  [buttonColor]="'success'"
  [disableSubmitButton]="!formsValid"
  (clickEmitter)="save()"
>
  <div class="full-width" data-layout="column" data-layout-gap="16px">
    <span class="contactNote"> {{ 'facilities.facilityContactNote' | language }} </span>

    <!-- Always hidden, needed for request for saving -->
    <app-facility-form-name
      [hidden]="true"
      [facility]="data.facility"
      (formNameValueChangeEmitter)="formName = $event; checkValidation()"
    ></app-facility-form-name>

    <!-- BillingContacts group -->
    <app-facility-form-billing-contacts
      [valid]="formsValid"
      [facility]="data.facility"
      [editContactFlow]="true"
      (formNameValueChangeEmitter)="formBillingContacts = $event; checkContactsValidation()"
    ></app-facility-form-billing-contacts>

    <!-- PrimaryContacts group -->
    <app-form-additional-contacts
      id="facilityPrimaryContacts"
      [contact]="primaryContact"
      [canEdit]="true"
      [showAddNewContactsButton]="!additionalContacts.length"
      [type]="'PRIMARY'"
      (formNameValueChangeEmitter)="formPrimaryContacts = $event; checkContactsValidation()"
      (addNewContactEmitter)="addFacilityContact()"
    ></app-form-additional-contacts>

    <!-- AdditionalContacts group -->
    <app-form-additional-contacts
      *ngFor="let contact of additionalContactsData; let i = index; last as isLast"
      [contact]="contact"
      [showAddNewContactsButton]="isLast && i < 4"
      [type]="'ADDITIONAL_PRIMARY'"
      [additionalContactIndex]="i"
      [canEdit]="true"
      (formNameValueChangeEmitter)="additionalContacts[i] = $event; checkContactsValidation()"
      (addNewContactEmitter)="addFacilityContact()"
      (deleteAdditionalContacts)="deleteAdditionalContacts(i, contact.id)"
    ></app-form-additional-contacts>
  </div>
</app-default-modal>
