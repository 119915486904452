<div class="containerMenu" (click)="$event.stopPropagation()">
  <app-input-kit
    [dataCy]="'searchFutureContainerInput'"
    [autocompleteName]="autoComplete"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [initialValue]="selectedContainer"
    [label]="'shared.labels.container' | language"
    [icons]="['close']"
    (iconClickEmitter)="$event === 'close' ? chooseContainerEmitter.emit(null) : null"
    (debounceTimeEndedEmitter)="searContainer($event)"
  ></app-input-kit>

  <mat-autocomplete #autoComplete="matAutocomplete" data-flex-align="center" [displayWith]="displayFn">
    <mat-option *ngIf="searching$ | async" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>

    <mat-option
      *ngFor="let cont of containers$ | async; trackBy: trackBy"
      class="connectSxAutocompleteOption"
      data-cy="futureContainerOption"
      [value]="cont"
      (click)="chooseContainerEmitter.emit(cont)"
    >
      <span>{{ cont.name }}</span>
      <small>{{ 'shared.labels.catalog' | language }}: {{ cont.catalog.referenceNumber }}</small>
    </mat-option>

    <mat-option *ngIf="(containers$ | async)?.length === 0 && (searching$ | async) === false" disabled class="connectSxAutocompleteOption">
      <span>{{ 'shared.labels.noItemsFound' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
