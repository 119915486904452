<button
  data-flex="100"
  [type]="buttonType"
  data-layout="row"
  data-layout-align="center center"
  data-layout-gap="5px"
  class="defaultButton"
  [attr.data-cy]="dataCy"
  [routerLink]="customRouterLink ? customRouterLink : null"
  [disabled]="disabled"
  [ngClass]="{
    primaryStyle: type === 'primary',
    secondaryStyle: type === 'secondary',
    outlineStyle: type === 'outline',
    primaryColor: color === 'primary',
    successColor: color === 'success',
    warnColor: color === 'warn',
    blueColor: color === 'blue',
    large: size === 'large',
    medium: size === 'medium',
    small: size === 'small',
    boldTitle: fontWeight === 600,
    iconButton: !title,
    whiteText: textColor === 'white',
    orangeText: textColor === 'orange',
    grayColor: defaultIconButton,
    circleButton: circleButton
  }"
  (click)="click($event)"
>
  @if (insertContentPosition === 'before') {
    <ng-container *ngTemplateOutlet="insertContentPositionTemplate" />
  }
  @if (iconPosition === 'before') {
    <ng-container *ngTemplateOutlet="iconContainer" />
  }
  <span class="buttonKitTitle" *ngIf="title">{{ title }}</span>
  @if (iconPosition === 'after') {
    <ng-container *ngTemplateOutlet="iconContainer" />
  }
  @if (insertContentPosition === 'after') {
    <ng-container *ngTemplateOutlet="insertContentPositionTemplate" />
  }
</button>

<ng-template #iconContainer>
  <app-icon
    *ngIf="icon"
    [ngClass]="{ animate: icon === 'close', rotate: rotateIcon }"
    [icon]="icon"
    [type]="iconOutline"
    [color]="textColor || color"
    [defaultCursor]="disabled"
    [size]="
      iconSize === 24
        ? 24
        : (type !== 'secondary' && type !== 'outline' && size !== 'small') ||
            (!title && (!circleButton || (circleButton && size === 'medium'))) ||
            bigIcon
          ? 20
          : 16
    "
  />
</ng-template>

<ng-template #insertContentPositionTemplate>
  <ng-content />
</ng-template>
