import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CatalogModel, PageableModel, ProductLineModel } from '@shared/models';
import { ImageModel } from '@shared/models/shared/image.model';
import { HttpHelperService } from './internal/http-helper.service';
import { AuthenticationService } from './auth.service';
import { CatalogsPageableParams } from '@shared/models/build-models';
import { Observable, of, catchError, map } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { TState } from '@shared/type/index.type';
import { environment } from '@environment';
import { ICatalogsAuditInfo, ICatalogsAuditInfoParams } from '@shared/interfaces/audits';
import { ICreateCatalogDTO } from '@shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CatalogService extends ApiService {
  assignProducts(id: string, body: string[]): Observable<boolean> {
    return this.post<void>(`catalogs/${id}/products/assign`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.assigned');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deactivate(catalogId: string, state: TState): Observable<boolean> {
    return this.put<void>(`catalogs/${catalogId}/state?state=${state}`, {}).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAttachment(id: string, imageId: string): Observable<boolean> {
    return this.delete<void>(`catalogs/${id}/attachment?catalogAttachmentId=${imageId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.attachmentWasDeleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteImage(id: string, imageId: string): Observable<boolean> {
    return this.delete<void>(`catalogs/${id}/image?catalogImageId=${imageId}`).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getCatalog(id: string, redirectToNotFoundPage: boolean = false): Observable<CatalogModel> {
    return this.get<CatalogModel>(`catalogs/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getCatalogsForReplenishment(eventId: string, manufacturerId: string): Observable<CatalogModel[]> {
    return this.get<CatalogModel[]>(`catalogs?eventId=${eventId}&manufacturerId=${manufacturerId}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getAttachments(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`catalogs/${id}/attachments`);
  }

  getImages(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`catalogs/${id}/images`);
  }

  getPageable(params: CatalogsPageableParams): Observable<PageableModel<CatalogModel>> {
    return this.get<PageableModel<CatalogModel>>(`catalogs/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(null))
    );
  }

  getProducts(id: string): Observable<ProductLineModel[]> {
    return this.get<ProductLineModel[]>(`catalogs/${id}/products`);
  }

  createCatalog(body: ICreateCatalogDTO): Observable<string> {
    return this.post<string>(`catalogs`, body).pipe(
      map((id: string) => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateCatalog(id: string, body: CatalogModel): Observable<boolean> {
    return this.put<void>(`catalogs/${id}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  putImageUrl(id: string, imageUrl: string): Observable<boolean> {
    return this.put<void>(`catalogs/${id}/image/url`, imageUrl).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  async uploadImage(id: string, formData: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}catalogs/${id}/image`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status === 200) {
      Promise.resolve(res).then();
      return true;
    } else {
      this.alertsService.showError('shared.alerts.errorMessages.loadImages');
      return false;
    }
  }

  putAttachmentUrl(inventoryId: string, imageUrl: string): Observable<boolean> {
    return this.put<string>(`catalogs/${inventoryId}/attachment/url`, imageUrl).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  async uploadAttachment(inventoryId: string, formData: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}catalogs/${inventoryId}/attachment`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status >= 400 && res.status < 600) {
      this.alertsService.showError('shared.alerts.errorMessages.loadAttachments');
      return false;
    } else {
      Promise.resolve(res).then();
      return true;
    }
  }

  getAuditInfo(params: ICatalogsAuditInfoParams): Observable<PageableModel<ICatalogsAuditInfo>> {
    return this.get<PageableModel<ICatalogsAuditInfo>>(`catalogs/audit-info`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }
}
