import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, inject } from '@angular/core';
import { LocationService } from '@services/location.service';
import { MatDialogRef } from '@angular/material/dialog';
import PlaceResult = google.maps.places.PlaceResult;
import { BehaviorSubject, finalize, take, takeUntil, tap } from 'rxjs';
import { CreateLocationModel } from '@shared/models/request-models/create-location.model';
import { AlertsService } from '@services/internal/alerts.service';
import { GoogleApiService } from '@services/internal/google-api.service';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { location } from '@shared/utils/location';
import { Geolocation } from '@capacitor/geolocation';
import { Position } from '@capacitor/geolocation/dist/esm/definitions';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss'],
  imports: [SharedModule]
})
export class CreateLocationComponent extends DestroySubscriptions implements AfterViewInit, OnDestroy {
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;

  private dialogRef = inject(MatDialogRef<CreateLocationComponent>);
  private locationService = inject(LocationService);
  private alertsService = inject(AlertsService);
  private googleApiService = inject(GoogleApiService);

  bounds: google.maps.LatLngBounds;
  name: string = '';
  locations: CreateLocationModel;
  useCurrentLocation: boolean;
  autocomplete: google.maps.places.Autocomplete;
  disableCheckbox: boolean;
  currentLocation: { lat: number; lng: number };
  touched: boolean = false;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  ngAfterViewInit(): void {
    this.googleApiService
      .loadGoogleMapsAPI()
      .pipe(
        takeUntil(this.subscriptions),
        tap(value => {
          if (value) {
            this.autocomplete = new google.maps.places.Autocomplete(this.locationInput.nativeElement);
            google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
              this.onAutocompleteLocation(this.autocomplete.getPlace());
            });
          }

          Geolocation.getCurrentPosition()
            .then((position: Position) => {
              this.currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
            })
            .catch((e: { message: string }) => {
              this.disableCheckbox = true;
              this.useCurrentLocation = false;
              this.alertsService.showError(null, e.message);
            });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    google.maps.event?.clearListeners(this.autocomplete, 'place_changed');
    this.unSubscribe();
  }

  close(id?: string): void {
    this.dialogRef.close(id);
  }

  changeAutocompleteOptions(): void {
    this.locations = null;

    if (this.useCurrentLocation) {
      this.bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(this.currentLocation.lat, this.currentLocation.lng),
        new google.maps.LatLng(this.currentLocation.lat, this.currentLocation.lng)
      );

      this.autocomplete.setOptions({ bounds: this.bounds, strictBounds: true });
    } else {
      this.autocomplete.setOptions({ bounds: null, strictBounds: false });
    }
  }

  onAutocompleteLocation(place: PlaceResult): void {
    this.locations = location(place);
    if (!this.name?.length) {
      this.name = this.locations.locationName;
    }
  }

  create(): void {
    this.loading$.next(true);

    const params: CreateLocationModel = { ...this.locations, ...{ name: this.name } };

    this.locationService
      .createLocation(params)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(id => {
        if (id) {
          this.close(id);
        }
      });
  }
}
