@let showAuditState = showAuditState$ | async;
@let nameInitials = nameInitials$ | async;
@let userName = userName$ | async;
@let totalElements = totalElements$ | async;
@let isMobile = isMobile$ | async;
@let organizationName = organizationName$ | async;

<div [ngClass]="{ openedSidebar: toggleSidebar && !isMobile }" [class.layoutDesktopContainer]="!isMobile">
  <ng-template #desktop>
    <div data-layout-align="space-between center">
      <app-desktop-header
        data-flex="100"
        data-layout="column"
        data-layout-align="space-between center"
        [hideSubNav]="hideSubNav"
        [toggleSidebar]="toggleSidebar"
        [totalElements]="totalElements"
        [userName]="userName"
        [organizationName]="organizationName"
        [nameInitials]="nameInitials"
        [showAuditState]="showAuditState"
        (logoutEmitter)="logout()"
        (getNotificationsEmitter)="getNotifications()"
        (toggleSidebarEmitter)="toggleSidebar = $event; saveToggle($event)"
        (openAIFeaturesListModalEmitter)="openAIFeaturesListModal()"
      />
    </div>
  </ng-template>

  <div class="contentContainer">
    <router-outlet />
  </div>
  <app-global-loader />
  <ng-container *appHasPermission="{ scope: 'notifications', permissionName: 'readNotifications' }">
    <app-messaging
      *ngIf="!isMobile"
      [loading]="loadingMessagingNotifications$ | async"
      [messages]="messagingNotifications"
      [totalMessagingNotifications]="totalMessagingNotifications"
    />
  </ng-container>
</div>

<ng-template #mobile>
  <div *ngIf="isOffline$ | async" class="offlineModeSplash">{{ 'shared.labels.offlineMode' | language }}</div>
  <app-mobile-screen-title
    [totalElements]="totalElements"
    (checkTotalNotifications)="getNotifications()"
    (menuTogglerEmitter)="showBurgerMenu = !showBurgerMenu; toggleScrollableProperty(showBurgerMenu)"
  />

  <app-mobile-menu
    [showDirectoryRoutes]="showDirectoryRoutes || isActive(['/directory'])"
    [showMovementRoutes]="showMovementRoutes || isActive(['/movement'])"
    [showInventoryRoutes]="showInventoryRoutes || isActive(['/inventory'])"
    [showBurgerMenu]="showBurgerMenu"
    [userName]="userName"
    [messagingNotifications]="messagingNotifications"
    [nameInitials]="nameInitials"
    [totalMessagingNotifications]="totalMessagingNotifications"
    [showAuditState]="showAuditState"
    (inventoryMenuTogglerEmitter)="showInventoryRoutes = $event"
    (directoryMenuTogglerEmitter)="showDirectoryRoutes = $event"
    (menuTogglerEmitter)="showBurgerMenu = $event; toggleScrollableProperty($event)"
    (logoutEmitter)="logout()"
    (openAIFeaturesListModalEmitter)="openAIFeaturesListModal()"
  />
</ng-template>

<ng-container *ngTemplateOutlet="isMobile ? mobile : desktop" />
