<div data-layout="row" data-layout-align="start center">
  <app-input-kit
    class="full-width"
    [label]="'shared.labels.locationAutocomplete' | language"
    [filterView]="filterView"
    [dataCy]="'locationInput'"
    [initialValue]="location"
    [deprecated]="inactiveState"
    [disabled]="disabled || !permissionService.isGranted('userLevel', 'manageInventoryMovementsAllowed')"
    [debounceEvents]="['keyup', 'focus']"
    [forceBlurEvent]="forceBlurEvent"
    [debounceTime]="1000"
    [borderTransparent]="borderTransparent"
    [icons]="[
      showClearIcon && !disabled ? 'close' : null,
      showMapViewIcon && location?.id ? 'location' : null,
      tooltip || inactiveState ? 'help' : null
    ]"
    [tooltipText]="inactiveState ? 'shared.tooltips.inactiveLocation' : tooltip"
    [autocompleteName]="locationAutocomplete"
    (debounceTimeEndedEmitter)="searchLocations($event)"
    (iconClickEmitter)="doIconAction($event)"
    (valueChangedEmitter)="valueChangedEmitter.emit($event)"
  ></app-input-kit>
</div>

<mat-autocomplete #locationAutocomplete="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
    <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
  </mat-option>
  <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
    <ng-container *ngIf="!(searchedData$ | async).length; else options">
      <mat-option class="connectSxAutocompleteOption" disabled>
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>
    </ng-container>
    <ng-template #options>
      <cdk-virtual-scroll-viewport
        itemSize="0"
        class="virtualAutocompleteScroll"
        [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
      >
        <mat-option
          *cdkVirtualFor="let location of searchedData$ | async; trackBy: trackBy"
          class="connectSxAutocompleteOption"
          data-cy="locationOption"
          [attr.data-cy-locationId]="location.id"
          [value]="location"
          (click)="selectOption(location)"
        >
          <span>{{ location.name || '-' }}</span>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </ng-template>
    <mat-option
      *ngIf="
        permissionService.isGranted('movements', 'createLocation') &&
        (loading$ | async) === false &&
        (initialLoading$ | async) === false &&
        showAddNew
      "
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="createLocation()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </ng-container>
</mat-autocomplete>
