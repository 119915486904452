<div data-layout="row" data-layout-align="space-between center" class="titleContainer">
  <app-title [capitalize]="false" [type]="'h4'" [title]="'shared.labels.processing' | language"></app-title>
</div>

<div data-layout="column" class="largeModalWindowContent" data-layout-gap="16px">
  <span
    >Searching for <b class="searchedValue">{{ data.searchValue }}</b
    >...</span
  >

  <span *ngIf="(data.loading | async) === false && (data.matchedInventory | async) === null" class="colorRed"
    >{{ 'shared.components.noMatchFound' | language }}.</span
  >

  <span *ngIf="(data.loading | async) === false && (data.matchedInventory | async) !== null" class="colorGreen">
    <b>{{ (data.matchedInventory | async).name }}</b> {{ 'shared.components.successfullyAdded' | language }}.
  </span>
</div>

<div
  data-layout-align="center"
  class="largeModalWindowButtonsContainer"
  data-layout="row"
  data-layout-xs="column"
  data-layout-gap="16px"
  data-layout-gap-xs="8px"
>
  <app-button-kit
    data-flex="50"
    data-flex-xs="100"
    [type]="'outline'"
    [color]="'primary'"
    [disabled]="(data.loading | async) === true"
    [title]="'shared.labels.tryAgain' | language"
    [fontWeight]="600"
    [size]="(isMobile$ | async) === true ? 'medium' : 'large'"
    (clickEmitter)="close(false)"
  ></app-button-kit>
  <app-button-kit
    data-flex="50"
    data-flex-xs="100"
    [type]="'primary'"
    [color]="'success'"
    [textColor]="'white'"
    [disabled]="(data.loading | async) === true"
    [title]="'shared.labels.finish' | language"
    [fontWeight]="600"
    [size]="(isMobile$ | async) === true ? 'medium' : 'large'"
    (clickEmitter)="close(true)"
  ></app-button-kit>
</div>
