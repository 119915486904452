<div
  class="inputWithSelectKitContainer"
  [ngClass]="{
    valueExist: focused,
    invalidInput: error && inputRef.touched,
    disabledInput: disabled,
    required: required && !disabled,
    background: background,
    focused: focused
  }"
  data-layout="row"
  data-layout-gap="8px"
>
  <mat-form-field data-flex="56px" class="selectFormField" [ngClass]="{ valueExist: type, disabledInput: disabled }">
    <mat-select
      #select
      disableOptionCentering="true"
      panelClass="inputWithSelectPanel"
      [ngModelOptions]="{ standalone: true }"
      [required]="true"
      [disabled]="disabled"
      [attr.data-cy]="'phoneFormatSelect'"
      (ngModelChange)="updateSelectValue($event); inputRef.control.markAsTouched()"
      [(ngModel)]="type"
    >
      <mat-option
        *ngFor="let item of ePhoneFormat | keyvalue"
        [attr.data-cy]="'phoneFormatOption'"
        [attr.data-cy-value]="item.key"
        [value]="item.key"
        >{{ item.value | language }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field class="inputFormField" [ngClass]="{ valueExist: input.value || focused, invalidInput: error, disabledInput: disabled }">
    <mat-label>{{ label }}</mat-label>
    <input
      #input
      #inputRef="ngModel"
      matInput
      autocomplete="off"
      [required]="required"
      [disabled]="disabled"
      [attr.data-cy]="'phoneInput'"
      [mask]="showMask ? mask : null"
      [showMaskTyped]="true"
      [validation]="showMask"
      [prefix]="showMask ? prefix : null"
      (focus)="focused = true"
      (blur)="focused = false"
      [ngModel]="value"
      (ngModelChange)="updateValue($event, inputRef?.valid)"
      maxlength="255"
    />
    <div *ngIf="tooltip" class="tooltipContainer">
      <app-tooltip-kit matSuffix [title]="tooltip" [position]="'below'"></app-tooltip-kit>
    </div>
  </mat-form-field>
  <span *ngIf="hint" class="inputHint">{{ hint }}</span>
  <span *ngIf="error && !focused && inputRef.touched" class="inputHint errorHint">{{ error }}</span>
</div>
