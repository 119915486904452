<div
  data-layout="column"
  data-layout-align="space-between start"
  class="desktopHeaderContainer"
  [ngClass]="{ open: toggleSidebar, DARK: (colorTheme$ | async) !== 'DARK_BLUE' }"
  (click)="!toggleSidebar ? toggleSidebarEmitter.emit(true) : null"
>
  <div class="menuContainer" data-layout="column" data-layout-align="start stretch" data-layout-gap="4px">
    <a
      (click)="$event.stopPropagation(); hideSubNavOptions()"
      data-layout="row"
      data-layout-align="start center"
      routerLink="/dashboard"
      class="imageContainer"
    >
      <img class="logoImage" [src]="getShortLogoURL" alt="ConnectSx" />
      <img class="labelImage" [hidden]="!toggleSidebar" [src]="getLabelURL" alt="ConnectSx Label" />
    </a>

    <span
      data-layout-align="center center"
      (click)="$event.stopPropagation(); toggleSidebarEmitter.emit(!toggleSidebar); toggleSidebar ? hideSubNavOptions() : null"
      class="toggleSideBarButton"
    >
      <app-icon
        [size]="16"
        [type]="'outline'"
        [icon]="toggleSidebar ? 'arrow_left_filled' : 'arrow_right_filled'"
        [color]="'overflow'"
        [dataCy]="'toggleSideBar'"
      ></app-icon>
    </span>

    <div class="overFlowScroll" data-layout="column" data-layout-align="start stretch" data-layout-gap="4px">
      <a
        (click)="$event.stopPropagation(); hideSubNavOptions()"
        data-layout="row"
        data-layout-gap="8px"
        [matTooltip]="'shared.tooltips.navigation.dashboard' | language"
        matTooltipPosition="right"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="toggleSidebar"
        data-layout-align="start center"
        class="navOption"
        routerLink="/dashboard"
        routerLinkActive="activeNavOption"
        data-cy="dashboardNavigation"
      >
        <app-icon [size]="24" [type]="isActive(['/dashboard']) ? 'filled' : 'outline'" [icon]="'category'"></app-icon>
        <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.dashboard' | language }}</span></a
      >

      <a
        (click)="$event.stopPropagation(); hideSubNavOptions()"
        data-layout="row"
        data-layout-gap="8px"
        [matTooltip]="'shared.tooltips.navigation.events' | language"
        matTooltipPosition="right"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="toggleSidebar"
        data-layout-align="start center"
        class="navOption"
        routerLink="/events/"
        routerLinkActive="activeNavOption"
        data-cy="eventsNavigation"
      >
        <app-icon [size]="24" [type]="isActive(['/events/']) ? 'filled' : 'outline'" [icon]="'calendar'"></app-icon>
        <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.events' | language }}</span></a
      >

      <a
        (click)="
          !toggleSidebar ? toggleSidebarEmitter.emit(true) : null;
          showDirectoryRoutes = false;
          showMovementRoutes = false;
          showInventoryRoutes = !showInventoryRoutes
        "
        data-layout="row"
        [attr.data-layout-gap]="toggleSidebar ? '8px' : '0px'"
        [matTooltip]="'shared.tooltips.navigation.inventory' | language"
        matTooltipPosition="right"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="toggleSidebar"
        data-layout-align="start center"
        class="navOption pointer"
        data-cy="inventoryNavigation"
        [ngClass]="{
          activeNavOption: isActive(['/inventory']) && !isActive(['statistics']),
          inactiveNavOption: !isActive(['/inventory']) && showInventoryRoutes
        }"
      >
        <app-icon
          [size]="24"
          [type]="isActive(['/inventory']) && !isActive(['statistics']) ? 'filled' : 'outline'"
          [icon]="'bag_2'"
        ></app-icon>
        <div data-flex="100" data-layout="row" data-layout-align="space-between center">
          <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.inventory' | language }}</span>
          <app-icon
            class="subnavigationArrow"
            [size]="20"
            [type]="'outline'"
            [icon]="showInventoryRoutes && toggleSidebar ? 'arrow_down_filled' : 'arrow_right_filled'"
          ></app-icon>
        </div>
      </a>

      <ng-container *ngIf="toggleSidebar && showInventoryRoutes">
        <div class="subnavContainer" data-layout="column" [@inOutAnimation]>
          <ng-container *ngFor="let item of inventoryRoutes; trackBy: trackBy">
            <a
              data-layout="row"
              data-layout-align="start center"
              *appHasPermission="{ scope: item.permissionScope, permissionName: item.permissionName }"
              [routerLink]="item.link"
              class="navOption subNavOption"
              routerLinkActive="activeNavOption"
              [attr.data-cy]="item.dataCy"
              [ngClass]="{ positionRelative: item.permissionName === 'readAudits' }"
            >
              @if (item.permissionName === 'readAudits' && showAuditState) {
                <span class="badge"></span>
              }
              {{ item.name | language }}</a
            >
          </ng-container>
        </div>
      </ng-container>

      <a
        (click)="
          !toggleSidebar ? toggleSidebarEmitter.emit(true) : null;
          showDirectoryRoutes = false;
          showInventoryRoutes = false;
          showMovementRoutes = !showMovementRoutes
        "
        data-layout="row"
        [attr.data-layout-gap]="toggleSidebar ? '8px' : '0px'"
        [matTooltip]="'shared.tooltips.navigation.movements' | language"
        matTooltipPosition="right"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="toggleSidebar"
        data-layout-align="start center"
        class="navOption pointer"
        data-cy="MovementNavigation"
        [ngClass]="{ activeNavOption: isActive(['/movement']), inactiveNavOption: !isActive(['/movement']) && showMovementRoutes }"
      >
        <app-icon class="rotate-right" [size]="24" [type]="'outline'" [icon]="'swap'"></app-icon>
        <div data-flex="100" data-layout="row" data-layout-align="space-between center">
          <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.movements' | language }}</span>
          <app-icon
            class="subnavigationArrow"
            [size]="20"
            [type]="'outline'"
            [icon]="showMovementRoutes && toggleSidebar ? 'arrow_down_filled' : 'arrow_right_filled'"
          ></app-icon>
        </div>
      </a>

      <ng-container *ngIf="toggleSidebar && showMovementRoutes">
        <div class="subnavContainer" data-layout="column" [@inOutAnimation]>
          <ng-container *ngFor="let item of movementRoutes; trackBy: trackBy">
            <a
              data-layout="row"
              data-layout-align="start center"
              *appHasPermission="{ scope: item.permissionScope, permissionName: item.permissionName }"
              [routerLink]="item.link"
              class="navOption subNavOption"
              routerLinkActive="activeNavOption"
              [attr.data-cy]="item.dataCy"
              >{{ item.name | language }}</a
            >
          </ng-container>
        </div>
      </ng-container>

      <a
        (click)="
          $event.stopPropagation();
          !toggleSidebar ? toggleSidebarEmitter.emit(true) : null;
          showInventoryRoutes = false;
          showMovementRoutes = false;
          showDirectoryRoutes = !showDirectoryRoutes
        "
        data-layout="row"
        [attr.data-layout-gap]="toggleSidebar ? '8px' : '0px'"
        [matTooltip]="'shared.tooltips.navigation.directory' | language"
        matTooltipPosition="right"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="toggleSidebar"
        data-layout-align="start center"
        class="navOption pointer"
        data-cy="directoryNavigation"
        [ngClass]="{ activeNavOption: isActive(['/directory']), inactiveNavOption: !isActive(['/directory']) && showDirectoryRoutes }"
      >
        <app-icon [size]="24" [type]="isActive(['/directory']) ? 'filled' : 'outline'" [icon]="'document'"></app-icon>
        <div data-flex="100" data-layout="row" data-layout-align="space-between center">
          <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.directory' | language }}</span>
          <app-icon
            class="subnavigationArrow"
            [size]="20"
            [type]="'outline'"
            [icon]="showDirectoryRoutes && toggleSidebar ? 'arrow_down_filled' : 'arrow_right_filled'"
          ></app-icon></div
      ></a>

      <ng-container *ngIf="toggleSidebar && showDirectoryRoutes">
        <div class="subnavContainer" data-layout="column" [@inOutAnimation]>
          <ng-container *ngFor="let item of directoryRoutes; trackBy: trackBy">
            <a
              data-layout="row"
              data-layout-align="start center"
              (click)="$event.stopPropagation()"
              *appHasPermission="{ scope: item.permissionScope, permissionName: item.permissionName }"
              [routerLink]="item.link"
              class="navOption subNavOption"
              routerLinkActive="activeNavOption"
              [attr.data-cy]="item.dataCy"
              >{{ item.name | language }}</a
            >
          </ng-container>
        </div>
      </ng-container>
      <a
        *appHasPermission="{ scope: 'report', permissionName: 'readReport' }"
        (click)="$event.stopPropagation(); hideSubNavOptions()"
        data-layout="row"
        data-layout-gap="8px"
        [matTooltip]="'shared.tooltips.navigation.reports' | language"
        matTooltipPosition="right"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="toggleSidebar"
        data-layout-align="start center"
        class="navOption"
        [routerLink]="'/statistics'"
        [ngClass]="{ activeNavOption: isActive(['/statistics']) }"
        data-cy="reportsNavigation"
      >
        <app-icon [size]="24" [type]="isActive(['/statistics']) ? 'filled' : 'outline'" [icon]="'download'"></app-icon>
        <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.reports' | language }}</span></a
      >
      <a
        (click)="$event.stopPropagation(); hideSubNavOptions()"
        data-layout="row"
        data-layout-gap="8px"
        [matTooltip]="'shared.tooltips.navigation.users' | language"
        matTooltipPosition="right"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="toggleSidebar"
        data-layout-align="start center"
        class="navOption"
        *appHasPermission="{ scope: 'user', permissionName: 'canReadUsers' }"
        routerLink="/users"
        routerLinkActive="activeNavOption"
        data-cy="usersNavigation"
      >
        <app-icon [size]="24" [type]="isActive(['/users']) ? 'filled' : 'outline'" [icon]="'users'"></app-icon>
        <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.users' | language }}</span></a
      >
    </div>
  </div>
  <div class="footerContainer" data-layout="column" data-layout-gap="4px">
    <a
      *ngIf="isCustodianOnly && toggleSidebar"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption activeNavOption activeNavOptionOrange"
    >
      {{ 'users.custodianOnly' | language }}</a
    >
    <a
      *ngIf="isDemoModeOnly && toggleSidebar"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption activeNavOption activeNavOptionOrange"
    >
      {{ 'shared.labels.demoMode' | language }}</a
    >
    <a
      data-layout="row"
      data-layout-gap="8px"
      [matTooltip]="'shared.tooltips.navigation.aISearch' | language"
      matTooltipPosition="right"
      matTooltipClass="connectSxTooltipMessage"
      [matTooltipDisabled]="toggleSidebar"
      data-layout-align="start center"
      class="navOption"
      data-cy="aISearchNavigation"
      (click)="openAIFeaturesListModalEmitter.emit()"
    >
      <app-icon [size]="24" [type]="'outline'" [icon]="'sparkle'"></app-icon>
      <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.aISearch' | language }}</span></a
    >
    <a
      *appHasPermission="{ scope: 'notifications', permissionName: 'readNotifications' }"
      (click)="$event.stopPropagation(); showModalNotifications()"
      data-layout="row"
      data-layout-gap="8px"
      [matTooltip]="'shared.tooltips.navigation.notifications' | language"
      matTooltipPosition="right"
      matTooltipClass="connectSxTooltipMessage"
      [matTooltipDisabled]="toggleSidebar"
      data-layout-align="start center"
      [ngClass]="{ activeNavOption: isActive(['/notifications']) }"
      class="navOption"
      data-cy="notificationsNavigation"
    >
      <span class="badgeContainer">
        <app-icon [size]="24" [type]="isActive(['/notifications']) ? 'filled' : 'outline'" [icon]="'notification'"></app-icon>
        <span *ngIf="totalElements > 0" class="badge"></span>
      </span>

      <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.notifications' | language }}</span></a
    >

    <a
      *appHasPermission="{ scope: 'settings', permissionName: 'readSettings' }"
      (click)="$event.stopPropagation(); hideSubNavOptions()"
      data-cy="menuItemSettings"
      data-layout="row"
      data-layout-gap="8px"
      [matTooltip]="'shared.tooltips.navigation.settings' | language"
      matTooltipPosition="right"
      matTooltipClass="connectSxTooltipMessage"
      [matTooltipDisabled]="toggleSidebar"
      data-layout-align="start center"
      class="navOption"
      [ngClass]="{ activeNavOption: isActive(['/settings']) }"
      routerLink="/settings/user-profile"
    >
      <app-icon [size]="24" [type]="isActive(['/settings']) ? 'filled' : 'outline'" [icon]="'setting'"></app-icon>
      <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.settings' | language }}</span></a
    >

    <a
      (click)="logoutEmitter.emit(); hideSubNavOptions()"
      data-cy="menuItemLogout"
      data-layout="row"
      data-layout-gap="8px"
      [matTooltip]="'shared.tooltips.navigation.logout' | language"
      matTooltipPosition="right"
      matTooltipClass="connectSxTooltipMessage"
      [matTooltipDisabled]="toggleSidebar"
      data-layout-align="start center"
      class="navOption"
      routerLink="/login"
    >
      <app-icon [size]="24" [type]="'outline'" [icon]="'logout'"></app-icon>
      <span *ngIf="toggleSidebar">{{ 'shared.tooltips.navigation.logout' | language }}</span></a
    >

    <div class="profileContainer" data-layout-align="space-between center" [ngClass]="{ profileDetailView: toggleSidebar }">
      <div data-layout="row" data-layout-gap="8px" data-layout-align="start center">
        <div
          class="profile"
          [matTooltip]="userName + ' / ' + organizationName"
          matTooltipPosition="right"
          matTooltipClass="connectSxTooltipMessage"
          [matTooltipDisabled]="toggleSidebar"
          data-layout-align="center center"
          routerLink="/settings/user-profile"
        >
          <span>{{ nameInitials }}</span>
        </div>
        <div *ngIf="toggleSidebar" data-layout="column" data-layout-gap="2px">
          <div class="profileName">{{ userName }}</div>
          <div
            data-layout="row nowrap"
            data-layout-align="start center"
            class="profileOrg"
            [ngClass]="{ pointer: (organizations$ | async).length > 1 }"
            (click)="$event.stopPropagation(); openOrgSwitcherModal()"
          >
            <span class="profileOrgName">{{ organizationName }}</span>
            <app-icon
              *ngIf="(organizations$ | async).length > 1"
              [size]="16"
              [type]="'outline'"
              [icon]="'arrow_right_filled'"
              [color]="'overflow'"
            ></app-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
