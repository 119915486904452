<form
  class="form-group-emphasized last-child"
  data-layout="column"
  [ngClass]="{ nullMargin: createEntityFlow }"
  [attr.data-layout-gap]="createEntityFlow ? '24px' : '16px'"
  [formGroup]="form"
>
  <app-title *ngIf="!createEntityFlow" [title]="'procedures.informationLabel' | language" [type]="'h5'"></app-title>

  <div class="form-group-emphasized-container" data-layout="column" data-layout-gap="16px">
    <div [attr.data-layout]="createEntityFlow ? 'column' : 'row'" data-layout-gap="16px" data-layout-xs="column">
      <app-input-kit
        data-flex="100"
        formControlName="name"
        [dataCy]="'procedureNameInput'"
        [initialValue]="form.get('name').value"
        [invalidInput]="form.get('name').invalid"
        [skeleton]="loading && initialLoading"
        [label]="'procedures.nameInputLabel' | language"
        [required]="true"
        [disabled]="form.get('name').disabled || loading"
      ></app-input-kit>
      <app-select-kit
        data-flex="100"
        [overflowHidden]="true"
        [selectData]="procedureTypes"
        [translateValue]="true"
        [dataCy]="'procedureTypeSelect'"
        [optionDataCy]="'procedureTypeOption'"
        [label]="'procedures.typeInputLabel' | language"
        [required]="true"
        [skeleton]="loading && initialLoading"
        [disabled]="form.get('type').disabled || loading"
        [parseKeyByProperty]="'value'"
        [parseValueByProperty]="'key'"
        [initialValue]="form.get('type').value"
        (selectEmitter)="form.get('type').markAsTouched(); form.get('type').setValue($event); form.markAsDirty()"
      ></app-select-kit>
    </div>
    <div data-layout="row" data-layout-gap="16px">
      <app-product-autocomplete
        data-flex="100"
        [removable]="removable"
        [skeleton]="loading && initialLoading"
        [selectedChips]="productsSelected"
        [multiple]="true"
        [showAddNewButton]="true"
        [disabled]="form.get('products').disabled || loading"
        [showOnlyAssignedOption]="permissionService.isRole('SALES')"
        (optionSelectedEmitter)="selectProductEmitter.emit($event)"
        (removeChipEmitter)="removeProductEmitter.emit($event)"
        (addProductEmitter)="createProduct()"
      ></app-product-autocomplete>
    </div>
    <div data-layout="row" data-layout-gap="16px">
      <app-modifiers-autocomplete
        data-flex="100"
        [selectedChips]="modifiers"
        [multiple]="true"
        [skeleton]="loading && initialLoading"
        [disabled]="form.disabled"
        [type]="'PROCEDURE'"
        [removable]="!form.disabled"
        (removeChipEmitter)="removeModifier($event)"
        (optionSelectedEmitter)="selectModifier($event)"
      ></app-modifiers-autocomplete>
    </div>
  </div>
</form>
