<div *ngIf="notes.length; else noitems" data-layout="column" data-layout-gap="16px">
  @for (note of notes; track note) {
    <div
      *ngIf="editedCommentId !== (note.id ?? note.auditInventoryCommentId)"
      class="commentListItem"
      data-layout="column"
      data-layout-gap="8px"
    >
      <div data-layout="row" data-layout-align="space-between start" data-layout-gap="16px">
        <span
          data-flex="100"
          data-cy="commentMessage"
          class="commentListItemMessage"
          [innerHTML]="bypassSecurityTrustHtml(note.comment || note.note || note.text)"
        ></span>

        <div data-layout="row" data-layout-align="end end" data-layout-gap="16px">
          <app-icon
            *ngIf="showCommentMarker"
            matTooltipPosition="below"
            matTooltipClass="connectSxTooltipMessage"
            [matTooltip]="'shared.tooltips.printCommentOnPDF' | language"
            [changeColorOnHover]="true"
            [type]="note.isPdfPrint ? 'filled' : 'outline'"
            [size]="20"
            [icon]="note.isPdfPrint ? 'paper_negative' : 'paper_plus'"
            [color]="note.isPdfPrint ? 'primary' : 'default'"
            (click)="markCommentEmitter.emit({ noteId: note.id, note: note.note, isPdfPrint: !note.isPdfPrint })"
          />

          <app-icon
            *ngIf="allowEdit && note.createdBy.id === currentUserId"
            matTooltipPosition="below"
            matTooltipClass="connectSxTooltipMessage"
            [matTooltip]="'shared.tooltips.editComment' | language"
            [changeColorOnHover]="true"
            [type]="'outline'"
            [size]="20"
            [icon]="'edit_square'"
            (click)="$event.stopPropagation(); editComment(note, '.wysiwygId-' + (note.id ?? note.auditInventoryCommentId))"
          />
        </div>
      </div>

      <div data-layout="row" data-layout-align="space-between center" class="commentListItemFooter">
        <span *ngIf="note.modifiedBy">{{ note.modifiedBy?.name }}</span>
        <span>{{ note.modifiedDatetime | userDate }}</span>
      </div>
    </div>

    <div *ngIf="editedCommentId === (note?.id ?? note.auditInventoryCommentId)" data-layout="column" data-layout-gap="16px">
      <app-wysiwyg
        id="commentInput"
        [ngClass]="['wysiwygId-' + (note?.id ?? note.auditInventoryCommentId)]"
        data-flex="100"
        [editValueFlow]="true"
        [maxLength]="1000"
        [innerHtml]="note.comment || note.note || note.text"
        (valueChanged)="editedComment = $event"
      ></app-wysiwyg>

      <div data-layout="row" data-layout-align="end center" data-layout-gap="16px">
        <app-button-kit
          data-flex="100px"
          data-flex-xs="50"
          data-cy="addCommentSaveButton"
          [color]="'success'"
          [textColor]="'white'"
          [size]="(isMobile$ | async) ? 'medium' : 'small'"
          [disabled]="!editedComment?.length"
          [type]="'primary'"
          [title]="'shared.labels.save' | language"
          (clickEmitter)="saveComment()"
        ></app-button-kit>

        <app-button-kit
          data-flex="100px"
          data-flex-xs="50"
          data-cy="addCommentCancelButton"
          [color]="'warn'"
          [size]="(isMobile$ | async) ? 'medium' : 'small'"
          [type]="'secondary'"
          [title]="'shared.labels.cancel' | language"
          (clickEmitter)="clear()"
        ></app-button-kit>
      </div>
    </div>
  }
</div>
<ng-template #noitems>
  <span class="labelNoItems">{{ 'shared.labels.noComments' | language }}</span>
</ng-template>
