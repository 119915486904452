<div class="full-width" data-layout="row" data-layout-align="start center">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'procedureInput'"
    [chipDataCy]="'procedureChip'"
    [chipRemoveData_cy]="'procedureRemoveChipButton'"
    [selectedChips]="selectedItems"
    [label]="'shared.labels.procedureAutocomplete' | language"
    [showAddChipButton]="true"
    [forceBlurEvent]="forceBlurEvent"
    [disabled]="disabled"
    [invalidInput]="invalid"
    [selectable]="true"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [tooltipText]="tooltip"
    [icons]="[tooltip ? 'help' : null]"
    [required]="required ? !selectedItems.length : false"
    [requiredView]="required ? !!selectedItems.length : false"
    [removable]="removable"
    [detailPageLink]="'/directory/procedures/edit/'"
    [autocompleteName]="procedureAutocomplete"
    (chipsRemovedEmitter)="removeProcedureChip($event.id)"
    (debounceTimeEndedEmitter)="searchProcedure($event)"
    (iconClickEmitter)="doIconAction($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [label]="'shared.labels.singleProcedureAutocomplete' | language"
      [dataCy]="'procedureInput'"
      [invalidInput]="invalid"
      [initialValue]="procedure"
      [disabled]="disabled"
      [forceBlurEvent]="forceBlurEvent"
      [debounceEvents]="['keyup', 'focus']"
      [filterView]="filterView"
      [debounceTime]="1000"
      [tooltipText]="tooltip"
      [icons]="[showClearIcon ? 'close' : null, procedure?.id && showOpenInNewIcon ? 'link' : null, tooltip ? 'help' : null]"
      [autocompleteName]="procedureAutocomplete"
      [linkDetailPage]="
        permissionService.isGranted('common', 'visitDetailPageAssignedEntity') ? '/directory/procedures/edit/' + procedure?.id : null
      "
      [showInvalidStateOnInit]="showInvalidStateOnInit"
      (debounceTimeEndedEmitter)="searchProcedure($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
      (iconClickEmitter)="doIconAction($event)"
    ></app-input-kit>
  </ng-template>

  <mat-autocomplete #procedureAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true; else options" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-template #options>
      <mat-option *ngIf="!searchedData.length; else data" disabled class="connectSxAutocompleteOption">{{
        'shared.labels.noItemsFound' | language
      }}</mat-option>
      <ng-template #data>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
        >
          <mat-option
            data-cy="procedureOption"
            *cdkVirtualFor="let procedure of searchedData"
            class="connectSxAutocompleteOption"
            [value]="procedure"
            (click)="selectOption(procedure)"
          >
            <span>{{ procedure.name }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-template>
    <mat-option
      *ngIf="
        permissionService.isGranted('directory', 'createProcedure') &&
        (loading$ | async) === false &&
        (initialLoading$ | async) === false &&
        showAddNewButton
      "
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="createProcedure()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
