<div data-hide data-show-lt-lg class="backgroundContainer">
  <div data-layout="row" data-layout-align="start center" class="connectSxTabContainer">
    <div
      class="connectSxTab"
      data-flex="50"
      [ngClass]="{ connectSxTabActive: selectedView === 'DETAILS' }"
      (click)="viewChangedEmitter.emit('DETAILS')"
    >
      {{ 'shared.labels.details' | language }}
    </div>

    <div
      class="connectSxTab"
      data-flex="50"
      [ngClass]="{ connectSxTabActive: selectedView === 'INFO' }"
      (click)="viewChangedEmitter.emit('INFO')"
    >
      {{ 'shared.labels.info' | language }}
    </div>
  </div>
</div>
