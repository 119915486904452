import { Directive, ElementRef, inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { takeUntil, throttleTime } from 'rxjs';

@Directive({
  selector: '[appResponsiveClass], [ngClass.lt-lg], [ngClass.gt-md], [ngClass.xs], [ngClass.sm], [ngClass.md], [ngClass.lg], [ngClass.xl]'
})
export class ResponsiveClassDirective extends DestroySubscriptions implements OnInit, OnChanges {
  @Input('ngClass.xs') xsClass: string | Record<string, boolean> | null = null;
  @Input('ngClass.sm') smClass: string | Record<string, boolean> | null = null;
  @Input('ngClass.md') mdClass: string | Record<string, boolean> | null = null;
  @Input('ngClass.lg') lgClass: string | Record<string, boolean> | null = null;
  @Input('ngClass.xl') xlClass: string | Record<string, boolean> | null = null;

  @Input('ngClass.lt-lg') ltLgClass: string | Record<string, boolean> | null = null;
  @Input('ngClass.gt-md') gtMdClass: string | Record<string, boolean> | null = null;

  private breakpointObserver = inject(BreakpointObserver);
  private renderer = inject(Renderer2);
  private el = inject(ElementRef);

  private appliedClasses: Set<string> = new Set();

  ngOnInit(): void {
    this.setupBreakpoints();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.xsClass ||
      changes.smClass ||
      changes.mdClass ||
      changes.lgClass ||
      changes.xlClass ||
      changes.ltLgClass ||
      changes.gtMdClass
    ) {
      this.unSubscribe();
      this.setupBreakpoints();
    }
  }

  private setupBreakpoints(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(throttleTime(300), takeUntil(this.subscriptions))
      .subscribe(result => {
        const xs = result.breakpoints[Breakpoints.XSmall]; // xs: w < 600px
        const sm = result.breakpoints[Breakpoints.Small]; // sm: 600px <= w < 960px
        const md = result.breakpoints[Breakpoints.Medium]; // md: 960px <= w < 1280px
        const lg = result.breakpoints[Breakpoints.Large]; // lg: 1280px <= w < 1920px
        const xl = result.breakpoints[Breakpoints.XLarge]; // xl: w >= 1920px

        const ltLg = !lg && !xl; // lt-lg: w < 1280px
        const gtMd = lg || xl; // gt-md: w > 960px

        this.clearClasses();

        if (xs && this.xsClass) {
          this.applyClasses(this.xsClass);
        }

        if (sm && this.smClass) {
          this.applyClasses(this.smClass);
        }

        if (md && this.mdClass) {
          this.applyClasses(this.mdClass);
        }

        if (lg && this.lgClass) {
          this.applyClasses(this.lgClass);
        }

        if (xl && this.xlClass) {
          this.applyClasses(this.xlClass);
        }

        if (ltLg && this.ltLgClass) {
          this.applyClasses(this.ltLgClass);
        }

        if (gtMd && this.gtMdClass) {
          this.applyClasses(this.gtMdClass);
        }
      });
  }

  private applyClasses(classInput: string | Record<string, boolean>): void {
    if (typeof classInput === 'string') {
      this.renderer.addClass(this.el.nativeElement, classInput);
      this.appliedClasses.add(classInput);
    } else if (typeof classInput === 'object' && classInput !== null) {
      for (const [className, shouldApply] of Object.entries(classInput)) {
        if (shouldApply) {
          this.renderer.addClass(this.el.nativeElement, className);
          this.appliedClasses.add(className);
        }
      }
    }
  }

  private clearClasses(): void {
    this.appliedClasses.forEach(className => this.renderer.removeClass(this.el.nativeElement, className));
    this.appliedClasses.clear();
  }
}
