<!--Required group-->
<form
  class="form-group-emphasized"
  [ngClass]="{ 'last-child nullMargin': createEntityFlow }"
  [attr.data-layout-gap]="createEntityFlow ? '24px' : '16px'"
  data-layout="column"
  [formGroup]="requiredFields"
>
  <app-title [title]="'products.productDetailsLabel' | language" [type]="'h5'"></app-title>

  <div class="form-group-emphasized-container" data-layout="column" data-layout-gap="16px">
    <div [attr.data-layout]="createEntityFlow ? 'column' : 'row'" data-layout-xs="column" data-layout-gap="16px">
      <app-input-kit
        data-flex="100"
        formControlName="name"
        [dataCy]="'createProductLineName'"
        [initialValue]="requiredFields.get('name').value"
        [label]="'products.nameInputLabel' | language"
        [invalidInput]="requiredFields.get('name').invalid"
        [required]="true"
        [disabled]="requiredFields.get('name').disabled"
      ></app-input-kit>
      <div data-flex="100" [attr.data-hide-xs]="!createEntityFlow">
        <app-manufacturer-autocomplete
          *ngIf="createEntityFlow"
          data-flex="100"
          [required]="true"
          [label]="'products.manufacturerInputLabel' | language"
          [showAddNew]="permissionService.isGranted('directory', 'createManufacturer') && requiredFields.get('manufacturerId').enabled"
          [manufacturer]="requiredFields.get('manufacturerId').value"
          [disabled]="requiredFields.get('manufacturerId').disabled"
          (selectedOptionEmitter)="requiredFields.get('manufacturerId').setValue($event)"
          (valueChangedEmitter)="requiredFields.get('manufacturerId').setValue($event)"
          (clearInputEmitter)="requiredFields.get('manufacturerId').setValue(null)"
          (addNewEmitter)="createManufacturer()"
        ></app-manufacturer-autocomplete>
      </div>
    </div>

    <app-modifiers-autocomplete
      data-flex="100"
      [multiple]="true"
      [selectedChips]="modifiers"
      [label]="'products.productTypes'"
      [disabled]="requiredFields.disabled"
      [type]="'PRODUCT'"
      [removable]="!requiredFields.disabled"
      (removeChipEmitter)="removeModifier($event)"
      (optionSelectedEmitter)="selectModifier($event)"
    ></app-modifiers-autocomplete>
  </div>
</form>
