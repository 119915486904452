<div class="notificationsWidgetContainer" data-layout="column" data-layout-gap="8px">
  <div
    data-layout-align="space-between end"
    data-layout="row"
    data-layout-xs="column"
    data-layout-align-xs="start stretch"
    data-layout-gap="8px"
    class="widgetTitleContainer"
  >
    <app-title
      [title]="
        (isMobile$ | async) === true
          ? selectedView === 'NOTIFICATIONS'
            ? ('notifications.notificationsLabel' | language)
            : ('notifications.mentions' | language)
          : ('notifications.notificationsAndMentions' | language)
      "
      [type]="'h4'"
    ></app-title>
    <div data-flex="220px" data-flex-lt-md="100" data-layout="row" class="connectSxTabContainer base">
      <app-tab-ui
        data-flex="50"
        [title]="'notifications.notificationsLabel' | language"
        [selected]="selectedView === 'NOTIFICATIONS'"
        (clickEmitter)="selectedView = 'NOTIFICATIONS'; getNotifications()"
      ></app-tab-ui>
      <app-tab-ui
        data-flex="50"
        [title]="'notifications.mentions' | language"
        [selected]="selectedView === 'MENTIONS'"
        (clickEmitter)="selectedView = 'MENTIONS'; getNotifications()"
      ></app-tab-ui>
    </div>
  </div>

  <div class="listNotificationsContainer">
    <app-list-notifications [loading]="loading$ | async" [notifications]="notifications$ | async"></app-list-notifications>
  </div>

  <div data-layout="row" data-layout-gap="8px" data-layout-align="end center">
    <app-button-kit
      data-flex-xs="50"
      [type]="'secondary'"
      [title]="'notifications.markAllAsRead' | language"
      [size]="'small'"
      (clickEmitter)="markAllAsRead()"
    ></app-button-kit>
    <app-button-kit
      data-flex-xs="50"
      [type]="'primary'"
      [textColor]="'white'"
      [title]="'shared.labels.viewAll' | language"
      [size]="'small'"
      (clickEmitter)="view()"
    ></app-button-kit>
  </div>
</div>
