<app-default-modal
  *ngIf="!lineView; else lineViewTemplate"
  [size]="'small'"
  [title]="'shared.components.chooseOrganization' | language"
  [disableSubmitButton]="selectedOrgId === currentOrgId"
  [buttonTitle]="'shared.labels.submit' | language"
  [scrollableContent]="true"
  (clickEmitter)="switchOrg(selectedOrgId)"
>
  <app-radio-kit
    class="subtitleContainer"
    [layout]="'column'"
    [value]="selectedOrgId"
    [radioButtons]="organizations"
    [parseKeyByProperty]="'name'"
    [parseValueByProperty]="'organizationId'"
    (radioEmitter)="selectedOrgId = $event"
  ></app-radio-kit>
</app-default-modal>

<ng-template #lineViewTemplate>
  <div
    data-layout="row nowrap"
    class="organizationChip"
    [ngClass]="{ pointer: organizations.length > 1 }"
    (click)="$event.stopPropagation(); organizations.length > 1 ? (showOrgSwitcher = !showOrgSwitcher) : null"
    [hidden]="showOrgSwitcher"
  >
    {{ organizationName$ | async }}
    <span *ngIf="organizations.length > 1" class="organizationChipArrow">></span>
  </div>

  <ng-container *ngIf="showOrgSwitcher">
    <div data-layout="row nowrap" data-layout-gap="8px" class="organizationSwitcherRow">
      <div
        *ngFor="let org of organizations; trackBy: trackBy"
        class="organizationChip"
        data-layout="row"
        data-layout-gap="0"
        [ngClass]="{ selectedOrganizationChip: org.organizationId === currentOrgId }"
        (click)="switchOrg(org.organizationId)"
      >
        {{ org.name }}
        <span *ngIf="org.organizationId === currentOrgId" class="organizationChipArrow">></span>
      </div>
    </div>
  </ng-container>
</ng-template>
