import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { PermissionService } from '@services/internal/permission.service';
import { PermissionDirectiveModel } from '@shared/models/permissions/permission.model';
import { pairwise, distinctUntilChanged, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appHasPermission]',
  standalone: false
})
export class HasPermissionDirective {
  @Input('appHasPermission') set appHasPermission(permissions: PermissionDirectiveModel) {
    this.currentPermissions = permissions;
    this.updateView();
  }

  private templateRef = inject(TemplateRef<any>);
  private viewContainer = inject(ViewContainerRef);
  private permissionService = inject(PermissionService);

  private currentPermissions: PermissionDirectiveModel;
  private hasView = false;

  constructor() {
    this.permissionService.permissionUpdated$
      .pipe(
        pairwise(),
        map(([_prev, curr]) => curr),
        distinctUntilChanged(),
        takeUntilDestroyed()
      )
      .subscribe(() => this.updateView());
  }

  /**
   * Check and show/hide template
   */
  private updateView(): void {
    const { scope, permissionName, conversely } = this.currentPermissions;
    const isPermissionGranted = this.permissionService.isGranted(scope, permissionName);
    const showTemplate = (isPermissionGranted && !conversely) || (!isPermissionGranted && conversely);

    if (showTemplate && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!showTemplate && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
