import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, forkJoin, take } from 'rxjs';
import { RequestRecipesService } from '@services/request-recipes.service';
import { UntypedFormGroup } from '@angular/forms';
import { IFilter, IRequestRecipeAssignmentDTOS, IRequestRecipeDTO } from '@shared/interfaces';
import { Router } from '@angular/router';
import { PreferenceCardsState } from '@shared/models/features/directory/preference-cards/preference-cards-state';
import { PhysicianModel, PreferenceCardModel, PreferenceCardAssignment } from '@shared/models';
import { MatDialog } from '@angular/material/dialog';
import { CreatePreferenceCardComponent } from '../partials/modals/create-preference-card/create-preference-card.component';
import { ConfirmModalConfig, CreateMediumEntityConfig } from '@constants';
import { PageDataService } from '@shared/modules/app-breadcrumbs/services/page-data.service';
import { EPageDataRoutes } from '@shared/modules/app-breadcrumbs/enums/page-data-routes.enum';
import { LanguageService } from '@services/internal/language.service';
import { UsersService } from '@services/users.service';
import { CatalogService } from '@services/catalog.service';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class RecipesStoreService {
  store$: BehaviorSubject<PreferenceCardsState> = new BehaviorSubject<PreferenceCardsState>(new PreferenceCardsState());

  constructor(
    private requestRecipesService: RequestRecipesService,
    private router: Router,
    private dialog: MatDialog,
    private catalogService: CatalogService
  ) {}

  setId(id: string) {
    this.store$.next({ ...this.store$.value, id });
  }

  setLoading(loading: boolean) {
    this.store$.next({ ...this.store$.value, loading });
  }

  setAssignedPhysicians(physicians: PhysicianModel[]) {
    this.store$.next({ ...this.store$.value, ...{ physicians } });
    this.checkValidation();
  }

  setNewPhysicians(newPhysicians: PhysicianModel[]) {
    const physicians = [...this.getPhysicians, ...newPhysicians];
    this.store$.next({ ...this.store$.value, ...{ physicians } });
    this.checkValidation();
  }

  removePhysicians(physicianId: string) {
    const physicians = this.getPhysicians;
    const index = physicians.findIndex(p => p.id === physicianId);
    physicians.splice(index, 1);
    this.store$.next({ ...this.store$.value, ...{ physicians } });
    this.checkValidation();
  }

  setForm(recipeForm: UntypedFormGroup) {
    this.store$.next({ ...this.store$.value, recipeForm });
    this.checkValidation();
  }

  setNewAssignments(assignments: PreferenceCardAssignment[]) {
    const newAssignments: PreferenceCardAssignment[] = [...this.getNewAssignments, ...assignments];

    this.store$.next({ ...this.store$.value, newAssignments });
    this.checkValidation();
  }

  getProductsForEachAssignment(data: PreferenceCardAssignment[]): void {
    data.forEach((assignment, index) => {
      this.catalogService
        .getProducts(assignment.catalog.id)
        .pipe(take(1))
        .subscribe(products => {
          assignment.product = products[0];

          if (index === data.length - 1) {
            this.setNewAssignments(data);
          }
        });
    });
  }

  get getId(): string {
    return this.store$.value.id;
  }

  get getRecipe(): PreferenceCardModel {
    return this.store$.value.recipe;
  }

  get getRecipeForm(): UntypedFormGroup {
    return this.store$.value.recipeForm;
  }

  get getPhysicians(): PhysicianModel[] {
    return this.store$.value.physicians;
  }

  get getNewAssignments(): PreferenceCardAssignment[] {
    return this.store$.value.newAssignments;
  }

  async loadRecipe(): Promise<void> {
    this.setLoading(true);
    return firstValueFrom(this.requestRecipesService.getRecipe(this.getId, true))
      .then(recipe => {
        this.store$.next({
          ...this.store$.value,
          recipe
        });

        this.getRecipeForm.markAsUntouched();
        this.store$.next({ ...this.store$.value, ...{ formsTouched: false } });
        PageDataService.setPageData(
          EPageDataRoutes.preferenceCard,
          recipe.name,
          recipe.state === 'ACTIVE'
            ? { status: LanguageService.instant('shared.statuses.active'), class: 'emphasizedSuccess' }
            : { status: LanguageService.instant('shared.statuses.inactive'), class: 'emphasizedRed' }
        );

        // Admin can edit any recipe, other user only created themselves CC-2501
        const user = UsersService.getUser();
        if (user.role !== 'ADMIN') {
          const editable: boolean = user.id === this.store$.value.recipe.createdBy.id;
          setTimeout(() => {
            this.store$.next({
              ...this.store$.value,
              ...{ editable }
            });
          });
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  create(): void {
    const confirm = this.dialog.open(
      ConfirmComponent,
      ConfirmModalConfig({
        description: LanguageService.instant('shared.alerts.prompt.assignPreferenceCardsToUsers'),
        title: LanguageService.instant('shared.alerts.prompt.confirmation')
      })
    );
    confirm
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (!result) return;
        this.requestRecipesService
          .createRecipe(this.getRequestParams)
          .pipe(take(1))
          .subscribe(id => {
            if (id) {
              this.dialog.closeAll();
              this.router.navigate([`/directory/preference-cards/edit/${id}`]);
            }
          });
      });
  }

  async save(getDataAfterSaving: boolean = true): Promise<void> {
    const id: string = this.getId;
    this.setLoading(true);
    const physicians = this.getPhysicians;
    const ids = physicians.map(p => p.id);
    const saved = await firstValueFrom(this.requestRecipesService.updateRecipe(id, this.getRequestParams));
    await firstValueFrom(this.requestRecipesService.updatePhysicians(id, ids));
    this.setLoading(false);
    if (!saved || !getDataAfterSaving) {
      return;
    }
    this.store$.value.recipeForm.reset();
    this.loadRecipe();
    this.loadAssignedPhysicians();
    this.loadAssignments();
  }

  async loadAssignedPhysicians(id?: string): Promise<PhysicianModel[]> {
    const assignedPhysicians = await firstValueFrom(this.requestRecipesService.getAssignedPhysicians(id || this.getId));
    this.setAssignedPhysicians(assignedPhysicians);
    return assignedPhysicians;
  }

  deleteNewAssignment(index: number): void {
    const newAssignments: PreferenceCardAssignment[] = [...this.getNewAssignments];
    newAssignments.splice(index, 1);
    this.store$.next({ ...this.store$.value, newAssignments });
  }

  async createAttachment(assignments: PreferenceCardAssignment[] = []): Promise<void> {
    if (!assignments.length) {
      return;
    }
    const promises: Promise<string>[] = [];
    this.buildAssignmentParams(assignments).forEach(a => {
      promises.push(firstValueFrom(this.requestRecipesService.createAssignments(this.getId, a)));
    });
    await firstValueFrom(forkJoin(promises));
    await this.loadAssignments();
  }

  async loadAssignments(id?: string, sort?: Sort): Promise<PreferenceCardAssignment[]> {
    const params: IFilter = {};
    params.sort = sort?.active ? `${sort.active},${sort.direction}` : 'modifiedDatetime,desc';
    const assignments = await firstValueFrom(this.requestRecipesService.getAssignments(id || this.getId, params)).then(
      data => data.content
    );
    this.store$.next({ ...this.store$.value, assignments });
    this.checkValidation();
    return assignments;
  }

  async deleteAssignment(assignmentId: string): Promise<void> {
    await firstValueFrom(this.requestRecipesService.deleteAssignment(assignmentId));
    await this.loadAssignments();
    this.setLoading(false);
  }

  resetState(): void {
    this.store$.next({ ...new PreferenceCardsState() });
  }

  async saveAttachment(editedAttachment: PreferenceCardAssignment) {
    this.setLoading(true);
    await firstValueFrom(
      this.requestRecipesService.updateAssignment(editedAttachment.id, this.buildAssignmentParams([editedAttachment])[0])
    );
    this.setLoading(false);
    await this.loadAssignments();
  }

  checkValidation(): void {
    const inValidNewAssignments = this.getNewAssignments.findIndex(a => a.quantity < 1) > -1;
    const physAdded = Boolean(this.store$.value.physicians.length);
    const assignAdded = Boolean(this.getId)
      ? Boolean(this.store$.value.assignments.length)
      : Boolean(this.getNewAssignments.length && !inValidNewAssignments);
    const duplicateName = this.store$.value.duplicateRecipe?.name;
    const formName = this.store$.value.recipeForm?.get('name')?.value;
    const validDuplicate = duplicateName ? duplicateName !== formName : true;

    if (!validDuplicate) {
      this.store$.value.recipeForm.get('name').setErrors({ invalidName: true });
    }

    if (!physAdded) {
      this.store$.value.recipeForm.get('physicianId').setErrors({ required: true });
    } else {
      this.store$.value.recipeForm.get('physicianId').setErrors(null);
    }

    setTimeout(() => {
      const validForm = this.getRecipeForm.valid && physAdded && assignAdded && validDuplicate;
      this.store$.next({ ...this.store$.value, ...{ validForm } });
      this.store$.next({ ...this.store$.value, ...{ formsTouched: this.getRecipeForm.touched } });
    });
  }

  /** When user click duplicate, we need to copy recipe and user should be forced to change the recipe name */
  async duplicate(recipe: PreferenceCardModel, currentRecipe: boolean = false): Promise<void> {
    await this.router.navigate([`/directory/preference-cards/list`]);
    const duplicateRecipe: PreferenceCardModel = currentRecipe ? this.getRecipe : recipe;
    this.resetState();

    this.store$.next({ ...this.store$.value, ...{ id: duplicateRecipe.id } });
    this.store$.next({ ...this.store$.value, ...{ duplicateRecipe } });

    this.loadAssignedPhysicians();
    this.loadAssignments().then(assignments => this.setNewAssignments(assignments));

    this.dialog.open(CreatePreferenceCardComponent, CreateMediumEntityConfig);
  }

  private get getRequestParams(): IRequestRecipeDTO {
    return {
      name: this.getRecipeForm.get('name').value,
      note: this.getRecipeForm.get('note').value,
      additionalPreferences: this.getRecipeForm.get('additionalPreferences').value,
      procedureId: this.getRecipeForm.get('procedureId').value?.id,
      requestRecipeAssignmentDTOS: this.buildAssignmentParams(this.getNewAssignments),
      requestRecipePhysicianDTOS: this.getPhysicians.map(p => ({ physicianId: p.id }))
    };
  }

  private buildAssignmentParams(assignments: PreferenceCardAssignment[]): IRequestRecipeAssignmentDTOS[] {
    return assignments.map(assignment => ({
      catalogId: assignment.catalog?.id || null,
      productId: assignment.product?.id,
      quantity: assignment.quantity,
      modifier: assignment.modifier || null
    }));
  }
}
