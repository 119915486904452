<div class="full-width" data-layout="row" data-layout-align="start center">
  <app-input-kit
    class="full-width"
    [required]="required"
    [label]="'shared.labels.priceSheet' | language"
    [dataCy]="'priceSheetInput'"
    [initialValue]="priceSheet"
    [disabled]="disabled"
    [forceBlurEvent]="forceBlurEvent"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [icons]="[showClearIcon ? 'close' : null, priceSheet?.id && showOpenInNewIcon ? 'link' : null]"
    [autocompleteName]="priceSheetAutocomplete"
    [linkDetailPage]="permissionService.isGranted('directory', 'readPriceSheets') ? '/directory/price-lists/' + priceSheet?.id : null"
    (debounceTimeEndedEmitter)="searchPriceSheet($event)"
    (iconClickEmitter)="doIconAction($event)"
  ></app-input-kit>

  <mat-autocomplete #priceSheetAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true; else options" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-template #options>
      <mat-option *ngIf="!searchedData.length; else data" disabled class="connectSxAutocompleteOption">{{
        'shared.labels.noItemsFound' | language
      }}</mat-option>
      <ng-template #data>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
        >
          <mat-option
            data-cy="priceSheetOption"
            *cdkVirtualFor="let priceSheet of searchedData"
            class="connectSxAutocompleteOption"
            [value]="priceSheet"
            (click)="selectOption(priceSheet)"
          >
            <span>{{ priceSheet.name }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-template>
  </mat-autocomplete>
</div>
