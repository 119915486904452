import { TrackingActionsModel } from '@shared/models/features/dashboard/tracking-actions-model';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DashboardModel, PageableModel } from '@shared/models';
import { EventVolumesModel } from '@shared/models/features/dashboard/dashboard.model';
import { Observable, of, catchError, BehaviorSubject, map, forkJoin, take } from 'rxjs';
import { HttpHelperService } from './internal/http-helper.service';
import {
  IDefaultQueryParams,
  IFilter,
  ISalesInfoParams,
  ISalesInfoResponse,
  ITopProductsUsedRequest,
  ITopProductsUsedResponse,
  ITrackingActionsRequest
} from '@shared/interfaces';
import { ApiService } from '@shared/classes/api-service';
import { TEventVolumePeriod } from '@shared/type/index.type';
import { ITopProductsValueAbleResponse } from '@shared/interfaces/dashboard/top-products';
import { IQuickLookup, IQuickLookupResponse } from '@shared/interfaces/dashboard/quick-lookup';
import { InventoriesService } from '@services/inventories.service';
import { LocalStorage } from '@services/internal/localstorage.service';
import { InventoryPageableParams } from '@shared/models/build-models';
import { convertStringNumbers } from '@shared/utils/convert-string-numbers';
import { IDashboardInventoryRequestExtensionRequest } from '@shared/interfaces/dashboard/dashboard-inventory-request-extension-request';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends ApiService {
  dashboard$: BehaviorSubject<DashboardModel> = new BehaviorSubject<DashboardModel>(new DashboardModel());
  eventVolumesForWeek$: BehaviorSubject<EventVolumesModel[]> = new BehaviorSubject<EventVolumesModel[]>([]);
  eventVolumesForMonth$: BehaviorSubject<EventVolumesModel[]> = new BehaviorSubject<EventVolumesModel[]>([]);
  constructor(private inventoriesService: InventoriesService) {
    super();
    this.getDashboard().then(data => this.dashboard$.next(data));
  }

  getAllDashboardData(): void {
    const isTimeExpired = this.isLoadDashboard();
    if (!isTimeExpired) {
      return;
    }

    const promiseDashboard$ = this.getDashboardData().pipe(map((dashboard: DashboardModel) => ({ type: 'dashboard', payload: dashboard })));

    const params: IFilter = {
      size: 1,
      expirationStatus: 'EXPIRED',
      state: 'ACTIVE'
    };
    const queryParams: InventoryPageableParams = new InventoryPageableParams(params);
    const promiseTotalExpiredItems$ = this.inventoriesService.getPageable(queryParams).pipe(
      map(data => ({
        type: 'totalExpiredItems',
        payload: data.totalElements
      }))
    );

    const paramsOverdue: IFilter = {
      size: 1,
      dueBackStatus: 'OVERDUE',
      state: 'ACTIVE'
    };
    const queryParamsOverdue: InventoryPageableParams = new InventoryPageableParams(paramsOverdue);
    const promiseTotalOverdueItems$ = this.inventoriesService.getPageable(queryParamsOverdue).pipe(
      map(data => ({
        type: 'totalOverdueItems',
        payload: data.totalElements
      }))
    );

    forkJoin([promiseDashboard$, promiseTotalExpiredItems$, promiseTotalOverdueItems$])
      .pipe(take(1))
      .subscribe((dashboardDataCommon: { payload: any; type: string }[]) => {
        let dashboard: DashboardModel = new DashboardModel();
        dashboardDataCommon.forEach(dash => {
          if (dash.type === 'dashboard') {
            dashboard = { ...dashboard, ...dash.payload };
          }
          if (dash.type === 'totalExpiredItems') {
            dashboard.totalExpiredItems = dash.payload;
          }
          if (dash.type === 'totalOverdueItems') {
            dashboard.totalOverdueItems = dash.payload;
          }
        });
        dashboard.inventoryTotalValue = convertStringNumbers(dashboard.inventoryTotalValue);
        const data = { ...dashboard, ...{ createdDateTime: new Date().getTime() } };
        this.dashboard$.next(data);
        LocalStorage.setItem('dashboard', JSON.stringify(data));
      });
  }

  getDashboardData(): Observable<DashboardModel> {
    return this.get<DashboardModel>(`dashboard/summary`).pipe(catchError(() => of(new DashboardModel())));
  }

  getVolumes(params: { period: TEventVolumePeriod }): Observable<EventVolumesModel[]> {
    return this.get<EventVolumesModel[]>(`dashboard/events/volumes`, {
      params
    }).pipe(
      map(data => {
        if (params.period === 'WEEK') {
          this.eventVolumesForWeek$.next(data);
        } else {
          this.eventVolumesForMonth$.next(data);
        }
        return data;
      })
    );
  }

  getAverageEventSalesValue(params: ISalesInfoParams): Observable<number> {
    return this.get<number>(`dashboard/averageEventSales`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTotalProductsUsed(params: ITopProductsUsedRequest): Observable<ITopProductsUsedResponse> {
    return this.get<ITopProductsUsedResponse[]>(`dashboard/topProductUsed`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTopProductValuable(params: ITopProductsUsedRequest): Observable<ITopProductsValueAbleResponse> {
    return this.get<ITopProductsValueAbleResponse[]>(`dashboard/topProductValuable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTotalEventSalesValue(params: ISalesInfoParams): Observable<ISalesInfoResponse> {
    return this.get<ISalesInfoResponse>(`dashboard/eventSales`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTrackingActions(params: ITrackingActionsRequest): Observable<TrackingActionsModel> {
    return this.get<TrackingActionsModel>(`dashboard/trackingActions`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getQuickLookup(params: IQuickLookup): Observable<IQuickLookupResponse> {
    return this.get<IQuickLookupResponse>(`dashboard/quick-lookup`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getInventoryRequestExtensionPageable(params: IDefaultQueryParams): Observable<PageableModel<IDashboardInventoryRequestExtensionRequest>> {
    return this.get<PageableModel<IDashboardInventoryRequestExtensionRequest>>(
      `dashboard/inventory-request-extension`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  destroy() {
    this.dashboard$.next(new DashboardModel());
    this.eventVolumesForWeek$.next([]);
    this.eventVolumesForMonth$.next([]);
  }

  /** Loading new Dashboard if previous obsolete > 3 minutes */
  private isLoadDashboard(): boolean {
    const dashboard = this.dashboard$.value;
    if (dashboard) {
      return dashboard?.createdDateTime + 1000 * 60 * 3 <= new Date().getTime();
    } else {
      return true;
    }
  }

  private async getDashboard(): Promise<DashboardModel> {
    const dash = await LocalStorage.getItem('dashboard');
    try {
      return JSON.parse(dash);
    } catch (_e) {
      return new DashboardModel();
    }
  }
}
