<div data-layout="row" data-layout-align="start center">
  <app-input-kit
    class="full-width"
    [label]="label"
    [dataCy]="'bomInput'"
    [initialValue]="bom"
    [disabled]="disabled || !permissionService.isGranted('userLevel', 'manageBomsAllowed')"
    [filterView]="filterView"
    [debounceEvents]="['keyup', 'focus']"
    [forceBlurEvent]="forceBlurEvent"
    [icons]="[
      showClearIcon && !disabled && permissionService.isGranted('userLevel', 'manageBomsAllowed') ? 'close' : null,
      showViewDetailsButton ? 'link' : null
    ]"
    [linkDetailPage]="permissionService.isRole('ADMIN') && bom?.id ? '/inventory/bill-of-materials/edit/' + bom?.id : null"
    [debounceTime]="1000"
    [autocompleteName]="bomAutocomplete"
    (debounceTimeEndedEmitter)="searchBOM($event)"
    (iconClickEmitter)="doIconAction($event)"
    (valueChangedEmitter)="valueChangedEmitter.emit($event)"
  ></app-input-kit>

  <mat-autocomplete #bomAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option *ngIf="!searchedData.length; else options" class="connectSxAutocompleteOption" disabled>
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>

      <ng-template #options>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
        >
          <mat-option
            *cdkVirtualFor="let bom of searchedData"
            data-cy="bomOption"
            class="connectSxAutocompleteOption"
            [value]="bom"
            (click)="selectBOM(bom)"
          >
            <span>{{ bom.name }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>

    <mat-option
      *ngIf="permissionService.isGranted('inventory', 'createBOM') && showAddNewButton"
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="createBOM()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
