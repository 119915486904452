import { UntypedFormGroup } from '@angular/forms';
import {
  EventModel,
  EventQuoteModel,
  ContactModel,
  ImageModel,
  InventoryModel,
  ManufacturerModel,
  NoteModel,
  PONumberModel,
  PriceAdjustmentModel,
  ProductLineModel,
  ContainerOnReserveModel,
  StatusesCount,
  InventoryRequestModel
} from '@shared/models';
import { EventActionModel } from '@shared/models/features/events/event-action.model';
import { TDutSort } from '../types';

export class EventsState {
  actions: EventActionModel;
  attachments: ImageModel[];
  loading: boolean;
  manufacturers: ManufacturerModel[];
  productLines: ProductLineModel[];
  containers: InventoryModel[];
  assignedDevicesOriginal: InventoryModel[];
  assignedDevicesGrouped: InventoryModel[];
  assignedDevicesSearched: InventoryModel[];
  assignedPacksOriginal: InventoryModel[];
  assignedPacksSearched: InventoryModel[];
  loadingAssignedDevices: boolean;
  event: EventModel;
  eventId: string;
  createRequestFromRecipe: boolean;
  eventLoading: boolean;
  inventoryRequests: InventoryRequestModel[];
  priceAdjustmentsExist: boolean;
  facilityContacts: ContactModel[];
  selectedFacilityContact: ContactModel[];
  isDistributorCanEdit: boolean;
  images: ImageModel[];
  notes: NoteModel[];
  valid: boolean;
  formsValid: boolean;
  formsTouched: boolean;
  required: UntypedFormGroup;
  assignments: UntypedFormGroup;
  poNumbers: PONumberModel[];
  latestFacilityContactId: string;
  devicesPriceChanged: boolean;
  existDeviceToMarkNoCharge: boolean;
  priceAdjustments: PriceAdjustmentModel[];
  dutInventoryDuplicates: InventoryModel[];
  modifiers: { modifierName: string }[];
  allowEdit: boolean;
  allowEditDevices: boolean;
  isFacilityCreditHold: boolean;
  quote: EventQuoteModel;
  containersOnReserve: ContainerOnReserveModel[];
  statusesCount: StatusesCount;
  isChargeAll: boolean;
  searchValue: string;
  dutSort: TDutSort;
  dutPacksSort: TDutSort;
  existAssignedInventories: boolean;
  isQuickTransfersEnabled: boolean;

  constructor() {
    this.actions = new EventActionModel();
    this.attachments = [];
    this.loading = false;
    this.manufacturers = [];
    this.productLines = [];
    this.loadingAssignedDevices = false;
    this.facilityContacts = [];
    this.selectedFacilityContact = [];
    this.quote = new EventQuoteModel();
    this.event = new EventModel();
    this.eventId = null;
    this.createRequestFromRecipe = false;
    this.eventLoading = false;
    this.inventoryRequests = [];
    this.priceAdjustmentsExist = false;
    this.isDistributorCanEdit = false;
    this.images = [];
    this.notes = [];
    this.valid = false;
    this.formsValid = false;
    this.formsTouched = false;
    this.poNumbers = [];
    this.latestFacilityContactId = '';
    this.devicesPriceChanged = false;
    this.existDeviceToMarkNoCharge = false;
    this.priceAdjustments = [];
    this.dutInventoryDuplicates = [];
    this.modifiers = [];
    this.allowEdit = false;
    this.allowEditDevices = false;
    this.isFacilityCreditHold = false;
    this.containers = [];
    this.containersOnReserve = [];
    this.statusesCount = new StatusesCount();
    this.isChargeAll = false;
    this.searchValue = null;
    this.assignedDevicesOriginal = [];
    this.assignedDevicesGrouped = [];
    this.assignedDevicesSearched = [];
    this.assignedPacksOriginal = [];
    this.assignedPacksSearched = [];
    this.dutSort = 'newestFirst';
    this.dutPacksSort = 'newestFirst';
    this.existAssignedInventories = false;
    this.isQuickTransfersEnabled = false;
  }
}
