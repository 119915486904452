<div #createEntityModalTitleContainer data-layout="row" data-layout-align="space-between center" class="titleContainer">
  <app-title [type]="'h4'" [title]="title"></app-title>
  <app-button-kit
    [icon]="'close'"
    [defaultIconButton]="true"
    [title]="''"
    [dataCy]="'closeModalButton'"
    [textColor]="'primary_text'"
    (clickEmitter)="close()"
  ></app-button-kit>
</div>

<div data-layout="row" class="largeModalWindowContent" [style.height]="contentHeight + 'px'">
  <ng-content></ng-content>
</div>

<div
  #createEntityModalButtonsContainer
  data-layout-align-lt-lg="center"
  [attr.data-layout-align]="!showPrevStepButton && (showCreateButton || showNextStepButton) && size === 'small' ? 'center' : 'end'"
  class="largeModalWindowButtonsContainer"
  data-layout="row"
  data-layout-gap="16px"
>
  <app-button-kit
    *ngIf="showPrevStepButton"
    data-flex-xs="100"
    [attr.data-flex]="size === 'small' ? '50%' : '250px'"
    [dataCy]="'prevStepButton'"
    [type]="'secondary'"
    [color]="'primary'"
    [title]="'shared.labels.previous' | language"
    [fontWeight]="600"
    [size]="'large'"
    (clickEmitter)="prevStep()"
  ></app-button-kit>
  <app-button-kit
    *ngIf="showNextStepButton"
    data-flex-xs="100"
    [attr.data-flex]="size === 'small' ? '50%' : '250px'"
    [type]="'primary'"
    [dataCy]="'nextStepButton'"
    [disabled]="disableNextStepButton"
    [color]="'primary'"
    [textColor]="'white'"
    [title]="'shared.labels.next' | language"
    [fontWeight]="600"
    [size]="'large'"
    (clickEmitter)="nextStep()"
  ></app-button-kit>
  <app-button-kit
    *ngIf="showCreateButton"
    data-flex-xs="100"
    [attr.data-flex]="size === 'small' ? '50%' : '250px'"
    [dataCy]="'createButton'"
    [type]="'primary'"
    [disabled]="disableCreateButton"
    [color]="'success'"
    [textColor]="'white'"
    [title]="createButtonTitle | language"
    [fontWeight]="600"
    [size]="'large'"
    (clickEmitter)="create()"
  ></app-button-kit>
</div>
