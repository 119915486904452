<div data-layout="column" data-layout-gap="16px" class="filterContainer">
  <app-input-kit
    [initialValue]="searchValue"
    [label]="'shared.labels.search' | language"
    [disabled]="(loading$ | async) === true"
    [debounceEvents]="['keyup']"
    [debounceTime]="1000"
    [placeholder]="'shared.labels.searchPlaceholder' | language"
    [icons]="['close']"
    (debounceTimeEndedEmitter)="searchValue = $event; searchCatalogs($event)"
    (iconClickEmitter)="doIconAction($event)"
  ></app-input-kit>

  <app-product-autocomplete
    [manufacturers]="manufacturers"
    [product]="product"
    [showClearIcon]="true"
    [disabled]="loading$ | async"
    [skipEmptyProducts]="true"
    (optionSelectedEmitter)="product = $event; searchCatalogs(searchValue)"
    (clearInputEmitter)="clear('PRODUCT_LINE')"
  ></app-product-autocomplete>

  <div
    *ngIf="event.eventType !== 'STOCK_ADJUSTMENT'"
    data-layout="column"
    data-layout-align="start start"
    data-layout-gap="10px"
    class="checkboxContainer"
  >
    <span class="caption defaultColor"> {{ 'events.detail.filterByManufacturers' | language }} </span>
    <div *ngFor="let manufacturer of manufacturers" data-layout="row" data-layout-align="start center" data-layout-gap="8px">
      <app-checkbox-kit
        [initialValue]="manufacturer.checked"
        [disabled]="(loading$ | async) === true"
        [title]="manufacturer.name"
        (checkEmitter)="manufacturer.checked = $event; searchCatalogs(searchValue)"
      ></app-checkbox-kit>

      <a routerLink="/directory/manufacturers/edit/{{ manufacturer.id }}">
        <app-icon
          [matTooltip]="'shared.tooltips.openInNewTab' | language"
          matTooltipClass="connectSxTooltipMessage"
          matTooltipPosition="above"
          [size]="16"
          [icon]="'link'"
          [type]="'outline'"
          [color]="'primary'"
        ></app-icon>
      </a>
    </div>
  </div>

  <div *ngIf="products.length > 0" data-layout="column" data-layout-align="start start" data-layout-gap="10px" class="checkboxContainer">
    <span class="caption defaultColor"> {{ 'events.detail.filterByProducts' | language }} </span>
    <div *ngFor="let product of products" data-layout="row" data-layout-align="start center" data-layout-gap="8px">
      <app-checkbox-kit
        [initialValue]="product.checked"
        [disabled]="(loading$ | async) === true"
        [title]="product.name"
        (checkEmitter)="product.checked = $event; searchCatalogs(searchValue)"
      ></app-checkbox-kit>

      <a routerLink="/inventory/products/edit/{{ product.id }}">
        <app-icon
          [matTooltip]="'shared.tooltips.openInNewTab' | language"
          matTooltipClass="connectSxTooltipMessage"
          matTooltipPosition="above"
          [size]="16"
          [icon]="'link'"
          [type]="'outline'"
          [color]="'primary'"
        ></app-icon>
      </a>
    </div>
  </div>
</div>
