<app-create-entity-modal
  [title]="'events.detail.createQuote' | language"
  [showCreateButton]="stepperRef?.selectedIndex === 1"
  [showPrevStepButton]="stepperRef?.selectedIndex === 1"
  [showNextStepButton]="stepperRef?.selectedIndex === 0"
  [disableNextStepButton]="stepperRef?.selectedIndex === 0 && !selectedItems.length"
  [disableCreateButton]="loading$ | async"
  [confirmationRequired]="true"
  [showCloseConfirmation]="!!selectedItems.length"
  (prevStepEmitter)="stepperRef.previous()"
  (nextStepEmitter)="stepperRef.next()"
  (createEmitter)="createQuote()"
>
  <mat-stepper #stepper [linear]="true" [disableRipple]="true" data-cy="createRequestModal">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [label]="'events.detail.selectItems' | language">
      <div data-layout="row" data-layout-lt-md="column" data-layout-gap="16px">
        <div data-flex="30" data-flex-lt-lg="35" data-flex-xs="none" data-layout="column" data-layout-gap="24px">
          <app-title class="subtitleContainer" [title]="'events.detail.selectItems' | language" [type]="'h5'"></app-title>

          <app-create-quote-filters
            [manufacturers]="data.manufacturers"
            [products]="data.products"
            [event]="data.event"
            [selectedCatalogs]="selectedItems"
            (catalogsSearchCompleted)="searchedItems = $event"
            (loadingEmitter)="loading$.next($event)"
          ></app-create-quote-filters>
        </div>

        <div data-flex="70" data-flex-lt-lg="65" data-flex-xs="none" data-layout="column">
          <app-create-quote-searched-items
            [loading]="loading$ | async"
            [searchedCatalogs]="searchedItems"
            [selectedCatalogs]="selectedItems"
            (selectCatalogEmitter)="selectCatalog($event)"
            (removeCatalogEmitter)="removeCatalog($event)"
          ></app-create-quote-searched-items>
        </div>
      </div>
    </mat-step>
    <mat-step [label]="'requests.create.preview' | language">
      <div data-layout="row" data-layout-lt-md="column" data-layout-gap="16px">
        <app-create-quote-selected-items
          data-flex="50"
          data-flex-xs="none"
          [selectedCatalogs]="selectedItems"
          (removeCatalogEmitter)="removeCatalog($event)"
        ></app-create-quote-selected-items>

        <div class="requestFieldsContainer" data-flex="50" data-flex-xs="none" data-layout="column" data-layout-gap="16px">
          <app-title class="subtitleContainer" [title]="'events.detail.completeYourQuote' | language" [type]="'h5'"></app-title>

          <app-input-kit
            [dataCy]="'eventQuoteNote'"
            [label]="'shared.labels.note' | language"
            [initialValue]="note"
            (valueChangedEmitter)="note = $event"
          ></app-input-kit>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</app-create-entity-modal>
