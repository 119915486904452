<div data-layout="column" data-layout-gap="12px" class="confirmCont">
  <div data-layout="row" data-layout-align="space-between center">
    <app-title
      *ngIf="data.type === 'START_PRELOADING'; else emptyEl"
      [title]="'offline.startPreloadingTitle' | language"
      [type]="'h5'"
      [capitalize]="false"
    ></app-title>
    <ng-template #emptyEl><span></span></ng-template>
    <span class="cancelButton" (click)="cancel()">{{ 'shared.labels.cancel' | language }}</span>
  </div>

  <ng-container [ngSwitch]="data.type">
    <div *ngSwitchCase="'START_PRELOADING'" data-layout="column" data-layout-gap="8px">
      <app-button-kit
        [disabled]="(offlineEvents$ | async)?.length === 0"
        [title]="'offline.startPreloadingButton' | language"
        [type]="'outline'"
        [color]="'primary'"
        [size]="'medium'"
        (clickEmitter)="confirm()"
      ></app-button-kit>
    </div>
    <ng-container *ngSwitchCase="'SYNCING'">
      <app-title [title]="'offline.syncingTitle' | language"></app-title>
      <mat-progress-bar mode="query"></mat-progress-bar>
    </ng-container>
    <ng-container *ngSwitchCase="'PROGRESS'">
      <app-title [title]="'offline.processingTitle' | language"></app-title>
      <mat-progress-bar mode="determinate" [value]="loadedPercentage$ | async"></mat-progress-bar>
    </ng-container>
  </ng-container>
</div>
