<div class="attachmentContainer" [ngClass]="{ resetPadding: resetPadding, resetMobilePadding: resetMobilePadding }" data-layout="column">
  <div *ngIf="title" data-layout="row" data-layout-gap="8px" data-layout-align="start center" class="attachmentTitleContainer">
    <app-title [title]="title" [type]="'h5'" [dataCy]="'attachmentsTitle'"></app-title>
    <app-tooltip-kit *ngIf="tooltip" [title]="tooltip" [position]="'below'"></app-tooltip-kit>
  </div>

  <div
    *ngIf="allowUpload || allowEdit"
    appDragDropFilesUpload
    (uploadFilesEmitter)="uploadFiles($event, 'attachment')"
    data-layout="column"
    class="attachmentUploadContainer"
  >
    <div>
      <div
        *ngIf="!hideUrlPanel"
        data-layout="row"
        data-layout-align="space-between center"
        data-layout-gap="4px"
        class="attachmentUrlInputContainer"
      >
        <input
          class="attachmentUrlInput"
          matInput
          autocomplete="off"
          #attachmentUrl
          [placeholder]="'shared.labels.urlInput' | language"
          (blur)="attachmentUrl.value = attachmentUrl.value.trim()"
          data-cy="attachFileUrlInput"
          type="url"
          pattern="'https://.*'"
        />

        <div *ngIf="attachmentUrl.value && attachmentUrl.value.match('https://.*')" class="attachmentIconContainer">
          <app-icon
            data-cy="attachFileSaveButton"
            [type]="'outline'"
            [icon]="'checkbox'"
            [color]="'success'"
            [size]="16"
            (click)="saveFileUrl(attachmentUrl, 'attachment'); attachmentUrl.value = ''"
          ></app-icon>
        </div>
      </div>

      <div data-hide data-show-xs>
        <input
          #attachmentInput
          (change)="uploadFiles({ event: $event, file: null }, 'attachment')"
          type="file"
          [accept]="accept"
          [multiple]="isMultipleFiles"
          class="hidden"
          data-cy="attachFileFileInput"
        />
      </div>
    </div>

    <div (click)="attachmentInput.click()" class="attachmentDropFileContainer" data-layout-align="center center" data-layout-gap="8px">
      <app-icon [type]="'outline'" [icon]="'plus'" [size]="16" [color]="'primary'" class="icon"></app-icon>
      <span class="label">{{ 'shared.labels.dragDropButton' | language }}</span>
    </div>
  </div>

  <div *ngIf="localAttachments.length; else noitems" class="attachmentItemsContainer" data-layout="column" data-layout-gap="8px">
    <div
      *ngFor="let file of localAttachments; trackBy: trackBy"
      (mouseenter)="file.hover = true"
      (mouseleave)="file.hover = false"
      class="attachmentItem"
      data-layout="row"
      data-layout-align="space-between center"
      data-layout-gap="2px"
    >
      <div class="attachmentItemLink" data-layout="row" data-layout-align="start center" data-layout-gap="4px">
        <app-icon [type]="'outline'" [icon]="'paper'" [defaultCursor]="true" [size]="16"></app-icon>
        <a [href]="file.url" data-cy="attachFileUrl">
          {{ file.urlName }}
        </a>
      </div>
      <app-icon
        *ngIf="allowEdit"
        data-cy="removeAttachmentButton"
        [type]="'outline'"
        [color]="file.hover ? 'primary' : 'default'"
        [icon]="'close'"
        [size]="16"
        (click)="$event.stopPropagation(); deleteFile(file)"
      ></app-icon>
    </div>
  </div>
  <ng-template #noitems>
    <span class="labelNoItems" *ngIf="!allowEdit">{{ 'shared.labels.noItems' | language }}</span>
  </ng-template>
</div>
