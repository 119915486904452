<ng-container *ngIf="type === 'single'; else range">
  <div
    class="datepickerKitContainer"
    [ngClass]="{
      valueExist: initialDate.value,
      invalidInput: initialDate.invalid,
      disabledInput: initialDate.disabled,
      withBackground: withBackground,
      focused: datePicker.opened
    }"
    data-layout="row"
    data-layout-align="start center"
  >
    <span class="datepickerLabel" *ngIf="label">{{ label }}</span>
    <mat-form-field
      class="datepickerFormField"
      [ngClass]="{ valueExist: initialDate.value, invalidInput: initialDate.invalid, disabledInput: initialDate.disabled }"
    >
      <input
        [attr.data-cy]="inputDataCy"
        matInput
        [matDatepicker]="datePicker"
        readonly
        [formControl]="initialDate"
        [min]="minDate"
        [max]="maxDate"
        (click)="datePicker.open()"
      />
      <mat-datepicker
        #datePicker
        [touchUi]="touchDatePicker"
        cdk-scrollable
        (yearSelected)="chosenYearHandler($event, initialDate)"
        (monthSelected)="chosenMonthHandler($event, datePicker)"
        startView="multi-year"
      ></mat-datepicker>
    </mat-form-field>
    <mat-datepicker-toggle [disableRipple]="true" class="datepickerToggle" [attr.data-cy]="togglerDataCy" [for]="datePicker">
      <app-icon matDatepickerToggleIcon *ngIf="icon" [icon]="icon" [type]="'outline'" [size]="20"></app-icon>
    </mat-datepicker-toggle>
  </div>
</ng-container>

<ng-template #range>
  <div
    class="datepickerKitContainer"
    [ngClass]="{
      valueExist: rangeStartDate.value || rangeEndDate.value,
      invalidInput: rangeStartDate.invalid || rangeEndDate.invalid,
      disabledInput: rangeStartDate.disabled || rangeEndDate.disabled,
      withBackground: withBackground,
      filterView: filterView,
      focused: picker.opened
    }"
    data-layout="row"
    data-layout-align="start start"
  >
    <mat-form-field
      class="datepickerFormField range"
      [ngClass]="{
        valueExist: rangeStartDate.value || rangeEndDate.value,
        invalidInput: rangeStartDate.invalid || rangeEndDate.invalid,
        disabledInput: rangeStartDate.disabled || rangeEndDate.disabled
      }"
    >
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate" (click)="picker.open()">
        <input data-cy="daterangeFromInput" matStartDate [formControl]="rangeStartDate" readonly />
        <input data-cy="daterangeToInput" matEndDate [formControl]="rangeEndDate" readonly />
      </mat-date-range-input>
      <mat-date-range-picker #picker [touchUi]="touchDatePicker" (closed)="rangePickerClosed()"></mat-date-range-picker>
    </mat-form-field>
    <div data-layout="row" data-layout-gap="8px" class="iconsContainer" data-layout-align="center center">
      <app-icon
        *ngIf="(rangeStartDate.value || rangeEndDate.value) && displayClearButton"
        [icon]="'close'"
        [type]="'outline'"
        [size]="16"
        (click)="$event.stopPropagation(); clear()"
      ></app-icon>
    </div>
    <mat-datepicker-toggle [disableRipple]="true" class="datepickerToggle" [attr.data-cy]="togglerDataCy" [for]="picker"
      ><app-icon matDatepickerToggleIcon *ngIf="icon" [icon]="icon" [type]="'outline'" [size]="20"></app-icon
    ></mat-datepicker-toggle>
  </div>
</ng-template>
