<div data-layout="row" data-layout-align="start center">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'containerInput'"
    [chipDataCy]="'containerChip'"
    [showAddChipButton]="true"
    [selectedChips]="selectedChips"
    [label]="label"
    [disabled]="disabled"
    [selectable]="true"
    [forceBlurEvent]="forceBlurEvent"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [icons]="[tooltip ? 'help' : null]"
    [tooltipText]="tooltip"
    [removable]="removable"
    [parseChipByProperty]="displayReferenceNumber ? 'catalogNumber' : 'name'"
    [showLinkToDetail]="showLinkToDetail && permissionService.isGranted('common', 'visitDetailPageAssignedEntity')"
    [detailPageLink]="'/inventory/inventories/edit/'"
    [autocompleteName]="containerAutocomplete"
    (chipsRemovedEmitter)="removeContainer($event)"
    (debounceTimeEndedEmitter)="searchContainer($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [label]="label"
      [filterView]="filterView"
      [invalidInput]="invalid"
      [forceBlurEvent]="forceBlurEvent"
      [dataCy]="'containerInput'"
      [autofocus]="autofocus"
      [initialValue]="container"
      [disabled]="disabled"
      [debounceEvents]="['keyup', 'focus']"
      [icons]="[showClearIcon ? 'close' : null, showBarcodeIcon ? 'scan' : null, tooltip ? 'help' : null]"
      [tooltipText]="tooltip"
      [debounceTime]="1000"
      [autocompleteName]="containerAutocomplete"
      (debounceTimeEndedEmitter)="searchContainer($event)"
      (iconClickEmitter)="doIconAction($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
      ><ng-content *ngIf="showBarcodeIcon" select="[scanner]"></ng-content
    ></app-input-kit>
  </ng-template>
</div>

<mat-autocomplete #containerAutocomplete="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
    <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
  </mat-option>
  <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
    <ng-container *ngIf="!searchedData.length; else options">
      <mat-option class="connectSxAutocompleteOption" disabled>
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>
    </ng-container>
    <ng-template #options>
      <cdk-virtual-scroll-viewport
        itemSize="0"
        class="virtualAutocompleteScroll"
        [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
      >
        <mat-option
          *cdkVirtualFor="let container of searchedData"
          data-cy="containerOption"
          class="connectSxAutocompleteOption"
          [value]="container"
          (click)="selectOption(container)"
        >
          <span data-cy="containerOptionName">{{ container.name }}</span>
          <small *ngIf="container.catalog?.referenceNumber"
            >{{ 'shared.labels.catalog' | language }}: {{ container.catalog?.referenceNumber }}</small
          >
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </ng-template>
  </ng-container>
</mat-autocomplete>
