<div data-layout="row" data-flex-xs="100" data-layout-align="space-between center" class="calculateButtons backgroundTransparent">
  <app-icon
    *ngIf="!hideControls"
    data-cy="decreaseInventoryCountButton"
    [class.disabled]="quantity < (allowZeroValue ? 1 : 2) || disableControls"
    [icon]="'minus'"
    [type]="'outline'"
    [disabled]="disableControls"
    [size]="(isMobile$ | async) === true ? 24 : 16"
    (click)="$event.stopPropagation(); calculate(false)"
  ></app-icon>

  <div data-layout="row" data-layout-align="start center">
    <div class="calculateInputContainer" [ngClass]="{ skipMaxWidth }">
      <input
        *ngIf="!disableControls"
        matInput
        autocomplete="false"
        [(ngModel)]="quantity"
        (keyup)="edit(quantity)"
        (blur)="edit(validate(quantity))"
        thousandSeparator=","
        [dropSpecialCharacters]="true"
        mask="separator"
        maxlength="5"
        data-cy="itemQtyInput"
        class="calculateInput"
      />

      <span *ngIf="disableControls && hideControls" class="caption">{{ label }}: </span>
      <span *ngIf="disableControls" class="caption" [ngClass]="{ colorRed: hideControls, qtyContainer: !hideControls }">{{
        quantity
      }}</span>
    </div>
  </div>

  <app-icon
    *ngIf="!hideControls"
    data-cy="incInventoryCountButton"
    [color]="'primary'"
    [class.disabled]="disableControls"
    [icon]="'plus'"
    [type]="'outline'"
    [size]="(isMobile$ | async) === true ? 24 : 16"
    (click)="$event.stopPropagation(); calculate(true)"
  ></app-icon>
</div>
