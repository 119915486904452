<app-default-modal
  [title]="'recipes.selectBOM' | language"
  [size]="'small'"
  [buttonTitle]="'shared.labels.submit' | language"
  [disableSubmitButton]="!manufacturerId || !bom.id"
  (clickEmitter)="submit()"
>
  <div class="full-width" data-layout="column" data-layout-gap="16px" data-layout-align="start stretch">
    <div class="staticProgressBarContainer">
      <mat-progress-bar *ngIf="loading$ | async" mode="query"></mat-progress-bar>
    </div>

    <app-bom-autocomplete
      [showClearIcon]="true"
      [bom]="bom"
      [required]="true"
      [disabled]="(loading$ | async) === true"
      (optionSelectedEmitter)="getBOMAssignments($event)"
      (clearInputEmitter)="clear('BOM')"
    ></app-bom-autocomplete>

    <app-select-kit
      [initialValue]="manufacturerId"
      [label]="'events.detail.manufacturerLabel' | language"
      [required]="true"
      [disabled]="!bom.id || (loading$ | async) === true"
      [selectData]="manufacturers"
      [parseValueByProperty]="'id'"
      [parseKeyByProperty]="'name'"
      [dataCy]="'manufacturerSelect'"
      [optionDataCy]="'manufacturerOption'"
      (selectEmitter)="selectManufacturer($event)"
    ></app-select-kit>
    <span *ngIf="recipeAssignments.length" class="caption"
      >{{ 'shared.labels.found' | language }} {{ recipeAssignments.length }} assignments</span
    >
  </div>
</app-default-modal>
