<app-create-entity-modal
  [title]="'events.detail.createEventLabel' | language"
  [disableCreateButton]="
    (required?.get('eventType').value === 'TRIAL' || required?.get('eventType').value === 'STOCKING_ORDER'
      ? selectedProducts.length === 0
      : false) ||
    (required?.get('eventType')?.value === 'STOCK_ADJUSTMENT' ? required?.invalid : !(selectedManufacturers | async).length) ||
    (processing$ | async) === true
  "
  [showCreateButton]="stepperRef?.selectedIndex === 1"
  [showPrevStepButton]="stepperRef?.selectedIndex === 1"
  [showNextStepButton]="stepperRef?.selectedIndex < 1"
  [confirmationRequired]="true"
  [showCloseConfirmation]="!!required?.get('eventType').value"
  [disableNextStepButton]="
    !required?.valid ||
    (required?.get('eventType').value === 'STOCKING_ORDER' && !selectedFacilityContact.length) ||
    required?.get('facilityId')?.value?.isCreditHold
  "
  (prevStepEmitter)="stepperRef.previous()"
  (nextStepEmitter)="stepperRef.next()"
  (createEmitter)="submit()"
>
  <mat-stepper #stepper [linear]="true" [disableRipple]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [label]="'events.detail.basicInformationLabel' | language" [stepControl]="required">
      <div data-layout="column" data-layout-gap="24px">
        <!--First group-->
        <app-form-step-first
          [excludeEventTypes]="data?.excludeEventTypes || []"
          [createEntityFlow]="true"
          [allowEdit]="true"
          [modifiers]="modifiers$ | async"
          [selectedFacilityContact]="selectedFacilityContact"
          [permittedRepresentative]="permittedRepresentative"
          [predefinedDateTime]="data?.dateTime ? data : null"
          [predefinedEventType]="predefinedEventType$ | async"
          [allowCreateAndInNewIconInAutocompletes]="canSeeAutocompleteIcons"
          [aAIEventCreationData]="data?.aAIEventCreationData"
          (formNameValueChangeEmitter)="required = $event; checkValidation()"
          (assignmentsValidatorUpdateEmitter)="assignmentsValidatorUpdate($event)"
          (assignPhysicianEmitter)="eventsStoreService.assignPhysician($event)"
          (assignFacilityEmitter)="eventsStoreService.assignFacility($event)"
          (selectedCustodyEmitter)="selectedCustody = $event"
          (removeFacilityContactEmitter)="removeFacilityContact($event)"
          (selectFacilityContactEmitter)="selectedFacilityContact.push($event)"
          (removeModifierEmitter)="removeModifier($event)"
          (selectModifierEmitter)="eventsStoreService.selectModifier($event)"
        ></app-form-step-first>
      </div>
    </mat-step>
    <mat-step [label]="'events.detail.assignmentsLabel' | language" [stepControl]="assignments">
      <!--Second group-->
      <app-form-step-second
        [valid]="(formsValid$ | async) && (required?.get('eventType').value === 'STOCKING_ORDER' ? !!selectedFacilityContact.length : true)"
        [createEntityFlow]="true"
        [allowEdit]="true"
        [prevStepForm]="required"
        [selectedCustody]="selectedCustody"
        [assignmentsValidator]="assignmentsValidator"
        [showFilterProducts]="showFilterProducts"
        [filterByRepresentative]="filterByRepresentative"
        [selectedProducts]="selectedProducts"
        [selectedContainers]="selectedContainers"
        [allowCreateAndInNewIconInAutocompletes]="canSeeAutocompleteIcons"
        (formNameValueChangeEmitter)="assignments = $event; checkValidation()"
        (addProductLineEmitter)="addProductLine()"
        (selectProductEmitter)="selectedProducts.push($event)"
        (selectContainerEmitter)="selectedContainers.push($event)"
        (selectManufacturerEmitter)="eventsStoreService.addManufacturers($event)"
        (filterByRepresentativeEmitter)="filterByRepresentative = !filterByRepresentative"
        (removeProductEmitter)="removeProduct($event)"
        (removeContainerEmitter)="removeContainer($event)"
        (removeManufacturerEmitter)="removeManufacturer($event)"
      ></app-form-step-second>
    </mat-step>
  </mat-stepper>
</app-create-entity-modal>
