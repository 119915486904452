<div class="full-width" data-layout="row" data-layout-align="start center">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'physicianInput'"
    [chipDataCy]="'physicianChip'"
    [showAddChipButton]="true"
    [chipRemoveData_cy]="'physicianRemoveChipButton'"
    [selectedChips]="selectedItems"
    [label]="'shared.labels.physicianAutocomplete' | language"
    [disabled]="disabled"
    [autoScrollOnFocus]="autoScrollOnFocus"
    [debounceEvents]="['keyup', 'focus']"
    [forceBlurEvent]="forceBlurEvent"
    [required]="!selectedItems.length && required"
    [requiredView]="!!selectedItems.length && required"
    [debounceTime]="1000"
    [invalidInput]="invalid"
    [parseChipByProperty]="'fullName'"
    [removable]="removable"
    [icons]="[tooltip ? 'help' : null]"
    [tooltipText]="tooltip"
    [showLinkToDetail]="showOpenInNewIcon && permissionService.isGranted('common', 'visitDetailPageAssignedEntity')"
    [detailPageLink]="'/directory/physicians/edit/'"
    [autocompleteName]="physicianAutocomplete"
    (chipsRemovedEmitter)="removeChip($event.id)"
    (debounceTimeEndedEmitter)="searchPhysicians($event)"
    (iconClickEmitter)="doIconAction($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [label]="'shared.labels.physician' | language"
      [invalidInput]="invalid"
      [showInvalidStateOnInit]="showInvalidStateOnInit"
      [dataCy]="'physicianInput'"
      [initialValue]="physician"
      [autoScrollOnFocus]="autoScrollOnFocus"
      [disabled]="disabled"
      [debounceEvents]="['keyup', 'focus']"
      [filterView]="filterView"
      [forceBlurEvent]="forceBlurEvent"
      [tooltipText]="tooltip"
      [debounceTime]="1000"
      [icons]="[showClearIcon ? 'close' : null, physician?.id && showOpenInNewIcon ? 'link' : null, tooltip ? 'help' : null]"
      [autocompleteName]="physicianAutocomplete"
      [linkDetailPage]="'/directory/physicians/edit/' + physician?.id"
      (debounceTimeEndedEmitter)="searchPhysicians($event)"
      (iconClickEmitter)="doIconAction($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
    ></app-input-kit>
  </ng-template>

  <mat-autocomplete #physicianAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option *ngIf="!(searchedData$ | async).length; else data" disabled class="connectSxAutocompleteOption"
        ><span>{{ 'shared.labels.noItemsFound' | language }}</span></mat-option
      >
      <ng-template #data>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
        >
          <mat-option
            *cdkVirtualFor="let physician of searchedData$ | async"
            data-cy="physicianOption"
            class="connectSxAutocompleteOption"
            [value]="physician"
            (click)="selectOption(physician)"
          >
            <span>{{ physician.fullName }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>
    <mat-option
      *ngIf="
        permissionService.isGranted('directory', 'createPhysician') &&
        (loading$ | async) === false &&
        (initialLoading$ | async) === false &&
        showAddNewButton
      "
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="createPhysician()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
