import { IDefaultQueryParams, IFilter } from '@shared/interfaces';
import { DefaultPagination } from '@constants';

export const DefaultQueryParams = (params: IFilter, size: number = DefaultPagination): IDefaultQueryParams => {
  const prop: string = params?.selectedMatSortHeaderActive || 'modifiedDatetime';
  const sort: string[] = params?.sort
    ? Array.isArray(params.sort)
      ? params.sort
      : [params.sort]
    : [`${prop},${params?.selectedMatSortHeaderDirection || 'desc'}`];
  return {
    page: params?.page || 0,
    size: params?.size || size,
    sort
  };
};
