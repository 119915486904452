<button
  data-flex="100"
  [type]="buttonType"
  data-layout="row"
  data-layout-align="center center"
  data-layout-gap="4px"
  class="defaultButton"
  [attr.data-cy]="dataCy"
  [disabled]="disabled"
  [ngClass]="{
    primaryStyle: type === 'primary',
    secondaryStyle: type === 'secondary',
    outlineStyle: type === 'outline',
    primaryColor: color === 'primary',
    successColor: color === 'success',
    warnColor: color === 'warn',
    large: size === 'large',
    medium: size === 'medium',
    small: size === 'small',
    boldTitle: fontWeight === 600,
    whiteText: textColor === 'white',
    orangeText: textColor === 'orange',
    buttonWithoutTitle: !title
  }"
  (click)="click($event)"
>
  <app-icon
    *ngIf="icon"
    [icon]="icon"
    [type]="iconOutline"
    [color]="textColor || color"
    [defaultCursor]="disabled"
    [size]="iconSize"
  ></app-icon>
  <span class="buttonKitTitle" *ngIf="title">{{ title }}</span>
  <app-icon
    [icon]="'arrow_down_filled'"
    [type]="iconOutline"
    [color]="textColor || color"
    [defaultCursor]="disabled"
    [size]="iconSize"
  ></app-icon>
</button>
