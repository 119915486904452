<form
  class="form-group-emphasized"
  [ngClass]="{ 'last-child nullMargin': createEntityFlow }"
  [attr.data-layout-gap]="createEntityFlow ? '24px' : '16px'"
  data-layout="column"
  [formGroup]="formName"
>
  <app-title [title]="'physicians.nameLabel' | language" [type]="'h5'"></app-title>

  <div class="form-group-emphasized-3-col">
    <app-input-kit
      data-flex-lt-lg="100"
      formControlName="firstName"
      [dataCy]="'physicianFirstNameInput'"
      [required]="true"
      [invalidInput]="formName.get('firstName').invalid"
      [initialValue]="formName.get('firstName').value"
      [label]="'physicians.firstNameInputLabel' | language"
      [disabled]="formName.get('firstName').disabled"
    ></app-input-kit>
    <app-input-kit
      data-flex-lt-lg="100"
      formControlName="lastName"
      [dataCy]="'physicianLastNameInput'"
      [required]="true"
      [initialValue]="formName.get('lastName').value"
      [invalidInput]="formName.get('lastName').invalid"
      [label]="'physicians.lastNameInputLabel' | language"
      [disabled]="formName.get('lastName').disabled"
    ></app-input-kit>
    <app-input-kit
      data-flex-lt-lg="100"
      formControlName="middleName"
      [dataCy]="'physicianMiddleNameInput'"
      [initialValue]="formName.get('middleName').value"
      [label]="'physicians.middleNameInputLabel' | language"
      [disabled]="formName.get('middleName').disabled"
    ></app-input-kit>
  </div>

  <app-tags-autocomplete
    data-flex="100"
    [selectedChips]="tags"
    [disabled]="formName.disabled"
    [removable]="!formName.disabled"
    [tagEntityType]="'PHYSICIAN'"
    (removeChipEmitter)="removeTags($event)"
    (selectedOptionEmitter)="addTags($event)"
  ></app-tags-autocomplete>
</form>
