<app-create-entity-modal
  [title]="'procedures.createProcedureTitle' | language"
  [showCreateButton]="true"
  [size]="'small'"
  [confirmationRequired]="!redirect"
  [disableCreateButton]="!formsValid || (loading$ | async) === true"
  [showCloseConfirmation]="form?.touched"
  (createEmitter)="!redirect ? submitWithoutRedirect() : submitWithSubscription(true)"
>
  <div data-layout="column" data-layout-gap="24px" class="full-width">
    <app-procedure-form
      [productsSelected]="productsSelected$ | async"
      [productLineLoading]="productLineLoading"
      [createEntityFlow]="true"
      [procedure]="procedure"
      [modifiers]="modifiers"
      [removable]="true"
      (formValueChangeEmitter)="form = $event; checkValidation()"
      (removeProductEmitter)="removeProduct($event)"
      (selectProductEmitter)="selectProduct($event)"
      (selectModifierEmitter)="selectModifier($event)"
      (removeModifierEmitter)="removeModifier($event)"
    ></app-procedure-form>
  </div>
</app-create-entity-modal>
