import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { RecipesPageableParams } from '@shared/models/build-models';
import { Observable, of, catchError, map } from 'rxjs';
import { PageableModel, PhysicianModel, PreferenceCardModel, PreferenceCardAssignment, PreferenceCardAssignmentDTO } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { IFilter, IRequestRecipeAssignmentDTOS, IRequestRecipeDTO } from '@shared/interfaces';
import { ApiService } from '@shared/classes/api-service';

@Injectable({
  providedIn: 'root'
})
export class RequestRecipesService extends ApiService {
  getPageable(params: RecipesPageableParams): Observable<PageableModel<PreferenceCardModel>> {
    return this.get<PageableModel<PreferenceCardModel>>(`requestRecipes/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getRecipe(id: string, redirectToNotFoundPage: boolean): Observable<PreferenceCardModel> {
    return this.get<PreferenceCardModel>(`requestRecipes/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  getAssignments(id: string, params: IFilter = {}): Observable<PageableModel<PreferenceCardAssignment>> {
    return this.get<PageableModel<PreferenceCardAssignment>>(
      `requestRecipes/${id}/assignments`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel())));
  }

  getAssignedPhysicians(id: string): Observable<PhysicianModel[]> {
    return this.get<PhysicianModel[]>(`requestRecipes/${id}/physicians`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of([]))
    );
  }

  createRecipe(body: IRequestRecipeDTO): Observable<string> {
    return this.post<string>(`requestRecipes`, body).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deactivate(id: string): Observable<boolean> {
    return this.put<string>(`requestRecipes/${id}/deactivate`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deactivated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateRecipe(id: string, body: IRequestRecipeDTO): Observable<boolean> {
    return this.put<void>(`requestRecipes/${id}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createAssignments(id: string, body: PreferenceCardAssignmentDTO): Observable<string> {
    return this.post<string>(`requestRecipes/${id}/assignments`, body).pipe(
      map((assignmentId: string) => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return assignmentId;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updatePhysicians(recipeId: string, ids: string[]): Observable<void> {
    return this.put<void>(`requestRecipes/${recipeId}/physicians/update`, ids).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateAssignment(assignmentId: string, body: IRequestRecipeAssignmentDTOS): Observable<void> {
    return this.put<void>(`requestRecipes/assignments/${assignmentId}`, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAssignment(assignmentId: string): Observable<void> {
    return this.delete<void>(`requestRecipes/assignments/${assignmentId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
