<div data-layout="column" data-layout-gap="8px" *ngIf="anchors.length" data-hide-lt-lg class="infoDataBlockAnchorsContainer">
  <div
    *ngFor="let item of anchors"
    class="infoBlockAnchorItem"
    data-layout="row"
    data-layout-gap="8px"
    data-layout-align="start center"
    (mouseenter)="item.hover = true"
    (mouseleave)="item.hover = false"
    (click)="scrollToAnchor(item.selector)"
  >
    <app-icon [type]="'outline'" [color]="item.hover ? 'primary' : 'default'" [icon]="item.icon" [size]="16"></app-icon>
    <span class="label">{{ item.title | language }}</span>
  </div>
</div>
