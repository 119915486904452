<span
  (click)="$event.stopPropagation(); showModalTooltip()"
  [matTooltipPosition]="position"
  [matTooltip]="isHtml ? tooltipContent.innerHTML : (title | language)"
  [matTooltipDisabled]="isMobile$ | async"
  matTooltipClass="connectSxTooltipMessage"
>
  <app-icon
    data-layout-align="center center"
    [icon]="icon"
    [type]="type"
    [color]="color"
    [size]="size"
    [defaultCursor]="defaultCursor"
  ></app-icon>
</span>

<ng-container>
  <div [hidden]="true" #tooltipContent>
    <ng-content></ng-content>
  </div>
</ng-container>
