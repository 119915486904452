export enum ERoles {
  ADMIN = 'shared.enums.role.operations',
  DISTRIBUTOR = 'shared.enums.role.distributor',
  SALES = 'shared.enums.role.sales',
  REGIONAL_MANAGER = 'shared.enums.role.regionalManager',
  ERROR = 'shared.enums.role.error',
  //Front-end only
  SUPER_ADMIN = 'shared.enums.role.superAdmin',
  PRIMARY_ADMIN = 'shared.enums.role.admin',
  FACILITY_MANAGER = 'shared.enums.role.facilityManager'
}
