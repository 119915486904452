<div #defaultModalTitleContainer data-layout="row" data-layout-align="space-between center" class="titleContainer">
  <div data-flex="90">
    <div data-layout="row" data-layout-gap="8px" data-layout-align="start center">
      <ng-template *ngIf="titleIconTemplate" [ngTemplateOutlet]="titleIconTemplate"></ng-template>
      <app-title
        [oneLineView]="true"
        [type]="'h4'"
        [capitalize]="capitalize"
        [title]="title"
        [showLoaderOnEmpty]="false"
        data-flex="100"
      ></app-title>
      <app-tooltip-kit *ngIf="tooltip" [title]="tooltip" [size]="16"></app-tooltip-kit>
    </div>
  </div>

  <app-button-kit
    [icon]="'close'"
    [defaultIconButton]="true"
    [textColor]="'primary_text'"
    [disabled]="disableCloseButton"
    (clickEmitter)="preventDefaultClose ? closeEmitter.emit() : dialogRef.close()"
  ></app-button-kit>
</div>

<div
  data-layout="row"
  class="largeModalWindowContent"
  [style.height]="this.contentHeight + 'px'"
  [ngClass]="{ scrollableContent: scrollableContent }"
>
  <ng-content></ng-content>
</div>

<div
  #defaultModalButtonsContainer
  [attr.data-layout-align]="size === 'small' ? 'center' : 'end'"
  *ngIf="buttonTitle"
  class="largeModalWindowButtonsContainer"
  data-layout="row"
  data-layout-gap="16px"
  data-layout-align-lt-lg="center"
>
  <app-button-kit
    *ngIf="showPrevStepButton"
    [attr.data-flex]="size === 'small' ? '50%' : '250px'"
    data-flex-xs="100"
    [type]="prevButtonType"
    [color]="prevButtonColor"
    [title]="prevButtonTitle"
    [textColor]="prevButtonType === 'primary' ? 'white' : null"
    [fontWeight]="600"
    [disabled]="disablePrevButton"
    [size]="'large'"
    (clickEmitter)="back()"
  ></app-button-kit>
  <app-button-kit
    *ngIf="!hideSubmitButton"
    [attr.data-flex]="size === 'small' && showPrevStepButton ? '50%' : '250px'"
    data-flex-xs="100"
    [dataCy]="'submitButton'"
    [type]="buttonType"
    [color]="buttonColor"
    [disabled]="disableSubmitButton"
    [textColor]="buttonType ? 'white' : null"
    [icon]="buttonIcon"
    [title]="buttonTitle"
    [fontWeight]="600"
    [size]="'large'"
    (clickEmitter)="click()"
  ></app-button-kit>
</div>
